import React, { useCallback } from "react";
import "styles/capability.css";
// import capabilityImage from '../assets/images/capability.jpg';
// import capabilityImage from '../assets/images/Thankyou.png';
import Card1 from "../assets/images/capability/Computers.png";
import Card2 from "../assets/images/capability/Cubes.png";
import Card3 from "../assets/images/capability/Blocks.png";
import Card4 from "../assets/images/capability/City.png";
import Card5 from "../assets/images/capability/Man.png";
import Card6 from "../assets/images/capability/Tube.png";
import Card7 from "../assets/images/capability/Haallway.png";
import Card8 from "../assets/images/capability/Sky.png";

import { MountSwitch } from "modules/mount.module";
import { Link } from "react-router-dom";
import store from "store";
import lodash from "lodash";
import { apiStoreClickData } from "api/users";

function Capability() {
  const userDetails = store.get("user") ?? {};
  const handleClick = useCallback((event) => {
    event.preventDefault();
    apiStoreClickData(userDetails.id, event.target.href);
    return (window.location.href = event.target.href);
  });

  return (
    <div>
      <div className="container">
        <div className="capability-background">
          <div className="mb-5">
            <div className="capability-heading mt-3 text-center mb-2">
              <h2 className="text-center">Blockchain Capability</h2>
            </div>
            <div className="capability-row mt-4">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="courseHeading">
                    <h4>Free Courses</h4>
                  </div>
                  <div className="row">
                    <div className="col-lg col-md col-sm-12">
                      <div className="gradient-box">
                        <div className="capability-card">
                          <div className="capability-card-image frame">
                            <img
                              src={Card7}
                              alt=""
                              className="gradient-image"
                            />
                            <p className="premium-para">FREE COURSE</p>
                          </div>
                          <div className="capability-heading">
                            <h4>
                              Web3 Business Logic
                              <br />
                              and Blockchain careers
                            </h4>
                          </div>
                          <div className="capability-content text-center">
                            <p className="pb-1">Key topics -</p>
                            <ul>
                              <li>1. Why Web3?</li>
                              <li>
                                2. Business logic for <br /> Blockchain Business{" "}
                              </li>
                              <li>3. Blockchain career</li>
                            </ul>
                            {/* <p>
                              <span className="white">Duration:</span> Unlimited
                              access
                            </p> */}
                          </div>
                          <div className="capability-footer free">
                            <MountSwitch
                              condition={true}
                            >
                              <MountSwitch.True>
                                <div className="d-flex" style={{justifyContent:"center"}}>
                                  <p>Duration:- 7 min</p>
                                </div>
                                <a
                                  href="https://youtu.be/M5St1SwZ7m8"
                                  onClick={handleClick}
                                >
                                  Get Started
                                </a>
                              </MountSwitch.True>
                              <MountSwitch.False>
                                <div className="d-flex text-center" style={{justifyContent:"center"}}>
                                  <p>Duration:- 7 min</p>
                                </div>
                                <Link to="/login" state={{ from: "/courses" }}>
                                  Get Started
                                </Link>
                              </MountSwitch.False>
                            </MountSwitch>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg col-md col-sm-12">
                      <div className="gradient-box">
                        <div className="capability-card">
                          <div className="capability-card-image frame">
                            <img
                              src={Card5}
                              alt=""
                              className="gradient-image"
                            />
                            <p className="premium-para">FREE COURSE</p>
                          </div>
                          <div className="capability-heading bg-transparent">
                            <h4 className="p-2">
                              Free course video in English for non-technical
                              graduates
                              <br />
                            </h4>
                          </div>
                          <div className="capability-content text-center">
                            <p>Key topics -</p>
                            <ul>
                              <li>1. Business logic to a web 3.0 solution</li>
                              <li>2. Used cases of web 3.0 business</li>
                              <li>3. History of Blockchain Technology</li>
                              {/* <li>
                                4. Introduction to Blockchain Duration: 90
                                minutes
                              </li> */}
                            </ul>
                            {/* <p>
                              <span className="white">Duration:</span> Unlimited
                              access
                            </p> */}
                          </div>
                          <div className="capability-footer free">
                            <MountSwitch
                              condition={!lodash.isEmpty(userDetails)}
                            >
                              <MountSwitch.True>
                                <div className="d-flex" style={{justifyContent:"center"}}>
                                  <p>Duration:- 90 min</p>
                                  {/* <p>
                                    ₹ 0* <span>Month</span>
                                  </p> */}
                                </div>
                                <a
                                  href="https://vimeo.com/887874345?share=copy"
                                  onClick={handleClick}
                                  className=" text-center text-decoration-none"
                                >
                                  Get Started
                                </a>
                              </MountSwitch.True>
                              <MountSwitch.False>
                                <div className="d-flex" style={{justifyContent:"center"}}>
                                  <p>Duration:- 90 min</p>
                                  {/* <p>
                                    ₹ 0* <span>Month</span>
                                  </p> */}
                                </div>
                                <Link to="/login" state={{ from: "/courses" }}>
                                  Get Started
                                </Link>
                              </MountSwitch.False>
                            </MountSwitch>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg col-md col-sm-12">
                      <div className="gradient-box">
                        <div className="capability-card">
                          <div className="capability-card-image frame">
                            <img
                              src={Card6}
                              alt=""
                              className="gradient-image"
                            />
                            <p className="premium-para">FREE COURSE</p>
                          </div>
                          <div className="capability-heading bg-transparent ">
                            <h4>
                              Free course video in English for technical
                              graduates
                              <br />
                            </h4>
                          </div>
                          <div className="capability-content text-center">
                            <p>Key topics -</p>
                            <ul>
                              <li>1. Cryptography</li>
                              <li>2. Database systems </li>
                              <li>3. Introduction to Bitcoin</li>
                            </ul>
                            {/* <p>
                              <span className="white">Duration:</span> Unlimited
                              access
                            </p> */}
                          </div>
                          <div className="capability-footer free">
                            <MountSwitch
                              condition={!lodash.isEmpty(userDetails)}
                            >
                              <MountSwitch.True>
                                <div className="d-flex" style={{justifyContent:"center"}}>
                                  <p>Duration:- 90 min</p>
                                  {/* <p>
                                    ₹ 0* <span>Month</span>
                                  </p> */}
                                </div>
                                <a
                                  href="https://vimeo.com/887877151?share=copy"
                                  onClick={handleClick}
                                  className=" text-center text-decoration-none"
                                >
                                  Get Started
                                </a>
                              </MountSwitch.True>
                              <MountSwitch.False>
                                <div  className="d-flex" style={{justifyContent:"center"}}>
                                  <p>Duration:- 90 min</p>
                                  {/* <p>
                                    ₹ 0* <span>Month</span>
                                  </p> */}
                                </div>
                                <Link to="/login" state={{ from: "/courses" }}>
                                  Get Started
                                </Link>
                              </MountSwitch.False>
                            </MountSwitch>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg col-md col-sm-12">
                      <div className="gradient-box">
                        <div className="capability-card">
                          <div className="capability-card-image frame">
                            <img
                              src={Card3}
                              alt=""
                              className="gradient-image"
                            />
                            <p className="premium-para">FREE COURSE</p>
                          </div>
                          <div className="capability-heading bg-transparent ">
                            <h4>
                              Free course video in Hindi for non-technical
                              graduates
                              <br />
                            </h4>
                          </div>
                          <div className="capability-content text-center">
                            <p>मुख्य टॉपिक्स -</p>
                            <ul>
                              <li>1. वेब 3.0 सलूशन के बिज़नेस लॉजिक</li>
                              <li>2. वेब 3.0 बिज़नेस के यूज़ केसेस </li>
                              <li>3. ब्लॉकचैन टेक्नोलॉजी का इतिहास</li>
                            </ul>
                            {/* <p>
                              <span className="white">Duration:</span> Unlimited
                              access
                            </p> */}
                          </div>
                          <div className="capability-footer free">
                            <MountSwitch
                              condition={!lodash.isEmpty(userDetails)}
                            >
                              <MountSwitch.True>
                                <div  className="d-flex" style={{justifyContent:"center"}}>
                                  <p>Duration:- 90 min</p>
                                  {/* <p>
                                    ₹ 0* <span>Month</span>
                                  </p> */}
                                </div>
                                <a
                                  href="https://vimeo.com/887883148?share=copy"
                                  onClick={handleClick}
                                  className=" text-center text-decoration-none"
                                >
                                  Get Started
                                </a>
                              </MountSwitch.True>
                              <MountSwitch.False>
                                <div  className="d-flex" style={{justifyContent:"center"}}>
                                  <p>Duration:- 90 min</p>
                                  {/* <p>
                                    ₹ 0* <span>Month</span>
                                  </p> */}
                                </div>
                                <Link to="/login" state={{ from: "/courses" }}>
                                  Get Started
                                </Link>
                              </MountSwitch.False>
                            </MountSwitch>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg col-md col-sm-12">
                      <div className="gradient-box">
                        <div className="capability-card">
                          <div className="capability-card-image frame">
                            <img
                              src={Card8}
                              alt=""
                              className="gradient-image"
                            />
                            <p className="premium-para">FREE COURSE</p>
                          </div>
                          <div className="capability-heading bg-transparent ">
                            <h4>
                              Free course video in Hindi for technical graduates
                              <br />
                            </h4>
                          </div>
                          <div className="capability-content text-center">
                            <p>मुख्य टॉपिक्स -</p>
                            <ul>
                              <li>1. क्रिप्टोग्राफ़ी</li>
                              <li>2. डेटाबेस सिस्टम्स </li>
                              <li>3. बिटकॉइन का इंट्रोडक्शन</li>
                            </ul>
                            {/* <p>
                              <span className="white">Duration:</span> Unlimited
                              access
                            </p> */}
                          </div>
                          <div className="capability-footer free">
                            <MountSwitch
                              condition={!lodash.isEmpty(userDetails)}
                            >
                              <MountSwitch.True>
                                <div  className="d-flex" style={{justifyContent:"center"}}>
                                  <p>Duration:- 90 min</p>
                                  {/* <p>
                                    ₹ 0* <span>Month</span>
                                  </p> */}
                                </div>
                                <a
                                  href="https://vimeo.com/887887378?share=copy"
                                  onClick={handleClick}
                                  className=" text-center text-decoration-none"
                                >
                                  Get Started
                                </a>
                              </MountSwitch.True>
                              <MountSwitch.False>
                                <div className="d-flex" style={{justifyContent:"center"}}>
                                  <p>Duration:- 90 min</p>
                                  {/* <p>
                                    ₹ 0* <span>Month</span>
                                  </p> */}
                                </div>
                                <Link to="/login" state={{ from: "/courses" }}>
                                  Get Started
                                </Link>
                              </MountSwitch.False>
                            </MountSwitch>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="soft-skill-box mb-5">
            {/* <div className="capability-heading mb-2">
              <h2 className="text-center">Soft-Skills Capability</h2>
            </div> */}
            <div className="capability-row mt-4">
              <div className="courseHeading">
                <h4>Premium Courses</h4>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-12">
                  <div className="gradient-box">
                    <div className="capability-card">
                      <div className="capability-card-image frame">
                        <img src={Card1} alt="" className="gradient-image" />
                        <p className="premium-para">PREMIUM</p>
                      </div>
                      <div className="capability-heading">
                        <h4>
                          Blockchain Certification
                          <br />
                          Course: Level 1 (English)
                        </h4>
                      </div>
                      <div className="capability-content text-center">
                        <p>Key Skills -</p>
                        <ul>
                          <li>1. Develop Smart Contracts</li>
                          <li>2. Test and deploy smart contracts</li>
                          <li>
                            3. Develop NFT market <br /> place and dynamic NFT
                          </li>
                        </ul>
                        <p>
                          <span className="white">Duration:</span> 6-12 months
                        </p>
                      </div>
                      <div className="capability-footer">
                        <p>
                          ₹ 10,000*/ <span>Month</span>
                        </p>
                        <a href="https://dqnot.courses.store/353496">
                          Get Started
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-12">
                  <div className="gradient-box">
                    <div className="capability-card">
                      <div className="capability-card-image frame">
                        <img src={Card2} alt="" />
                        <p className="premium-para">PREMIUM</p>
                      </div>
                      <div className="capability-heading">
                        <h4>
                          Blockchain Certification
                          <br />
                          Course: Level 1 (Hindi)
                        </h4>
                      </div>
                      <div className="capability-content text-center">
                        <p>प्रमुख कौशल -</p>
                        <ul>
                          <li>1. स्मार्ट कॉन्ट्रैक्ट विकसित करना</li>
                          <li>
                            2. स्मार्ट कॉन्ट्रैक्ट का <br /> परीक्षण और तैनाती
                            करें
                          </li>
                          <li>
                            3. एनएफटी मार्केट प्लेस और <br />
                            गतिशील एनएफटी विकसित करें
                          </li>
                        </ul>
                        <p>
                          <span className="white">अवधि:</span> 6-12 महीने
                        </p>
                      </div>
                      <div className="capability-footer">
                        <p>
                          ₹ 10,000*/ <span>Month</span>
                        </p>
                        <a href="https://dqnot.courses.store/353297?utm_source%3Dwebsite%26utm_medium%3Dblockskillo-course-referral%26utm_campaign%3Dblockskillo-website">
                          Get Started
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-12">
                  <div className="gradient-box">
                    <div className="capability-card">
                      <div className="capability-card-image frame">
                        <img src={Card3} alt="" />
                        <p className="premium-para">PREMIUM</p>
                      </div>
                      <div className="capability-heading">
                        <h4>
                          Blockchain Certification
                          <br />
                          Course: Level 2 (English)
                        </h4>
                      </div>
                      <div className="capability-content text-center">
                        <span className="white"></span>
                        <p>Key Skills -</p>
                        <ul>
                          <li>1. Upgrade smart contracts</li>
                          <li>
                            2. Enhance the performance <br />
                            of smart contracts
                          </li>
                          <li>3. Make the smart contracts cheaper</li>
                        </ul>
                        <p>
                          <span className="white">Duration:</span> 3-6 months
                        </p>
                      </div>
                      <div className="capability-footer">
                        <p>
                          ₹ 10,000*/ <span>Month</span>
                        </p>
                        <a href="https://dqnot.courses.store/353292?utm_source%3Dwebsite%26utm_medium%3Dblockskillo-course-referral%26utm_campaign%3Dblockskillo-website">
                          Get Started
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-12">
                  <div className="gradient-box">
                    <div className="capability-card">
                      <div className="capability-card-image frame">
                        <img src={Card4} alt="" />
                        <p className="premium-para">PREMIUM</p>
                      </div>
                      <div className="capability-heading">
                        <h4>
                          Blockchain Certification <br />
                          Course: Level 2 (Hindi)
                        </h4>
                      </div>
                      <div className="capability-content text-center">
                        <p>प्रमुख कौशल -</p>
                        <ul>
                          <li>1. स्मार्ट कॉन्ट्रैक्ट को अपग्रेड करें</li>
                          <li>2. स्मार्ट कॉन्ट्रैक्ट का प्रदर्शन बढ़ाएँ</li>
                          <li>3. स्मार्ट कॉन्ट्रैक्ट को सस्ता बनाएं</li>
                        </ul>
                        <p>
                          <span className="white">अवधि:</span> 3-6 महीने
                        </p>
                      </div>
                      <div className="capability-footer">
                        <p>
                          ₹ 10,000*/ <span>Month</span>
                        </p>
                        <a href="https://dqnot.courses.store/353499">
                          Get Started
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-12">
                  <div className="gradient-box">
                    <div className="capability-card">
                      <div className="capability-card-image frame">
                        <img src={Card6} alt="" />
                        <p className="premium-para">PREMIUM</p>
                      </div>
                      <div className="capability-heading">
                        <h4 className="text-center">
                          Startupster (Setting <br />
                          Up And Funding A <br /> Startup)
                        </h4>
                      </div>
                      <div className="capability-content text-center">
                        <p>Key topics -</p>
                        <ul>
                          <li>1. Setup a Founding Team</li>
                          <li>
                            2. Product and market <br /> development
                          </li>
                          <li>3. Selling the Startup</li>
                        </ul>
                        <p>
                          <span className="white">Duration:</span> 30 days
                        </p>
                      </div>
                      <div className="capability-footer">
                        <p>₹ 25,000</p>
                        <a href="https://dqnot.courses.store/353515">
                          Get Started
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-12">
                  <div className="gradient-box">
                    <div className="capability-card">
                      <div className="capability-card-image frame">
                        <img src={Card5} alt="" />
                        <p className="premium-para">PREMIUM</p>
                      </div>
                      <div className="capability-heading">
                        <h4 className="text-center">
                          The Job Workshop <br />
                          (Interview Prep Course)
                        </h4>
                      </div>
                      <div className="capability-content text-center">
                        <p>Key topics -</p>
                        <ul>
                          <li>1. Branding process</li>
                          <li>2. Story-telling</li>
                          <li>3. Interview skills</li>
                        </ul>
                        <p>
                          <span className="white">Duration:</span> 30 days
                        </p>
                      </div>
                      <div className="capability-footer">
                        <p>₹ 10,000</p>
                        <a href="https://dqnot.courses.store/353517">
                          Get Started
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-12">
                  <div className="gradient-box">
                    <div className="capability-card">
                      <div className="capability-card-image frame">
                        <img src={Card1} alt="" />
                        <p className="premium-para">PREMIUM</p>
                      </div>
                      <div className="capability-heading">
                        <h4 className="text-center">
                          Blockchain Developers
                          <br /> Communication <br />
                          Skills (WIP)
                        </h4>
                      </div>
                      <div className="capability-content text-center">
                        <p>Key topics - </p>
                        <ul>
                          <li>
                            1. Empathy (Development <br /> life-cycle)
                          </li>
                          <li>2. Choosing right sentences</li>
                          <li>3. Effective delivery of the language</li>
                        </ul>
                        <p>
                          <span className="white">Duration:</span> 30 days
                        </p>
                      </div>
                      <div className="capability-footer">
                        <p>₹ 25,000</p>
                        <a href="https://dqnot.courses.store/353496">
                          Get Started
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Capability;
