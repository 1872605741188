import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";

import NoMatch from "./NoMatch";
import WebLayout from "./layouts/WebLayout";
import PrivateRoutes from "layouts/PrivateRoutes";

import MainPage from "pages/main-page";
import CoursesPage from "pages/capability";
import SkillTokens from "pages/skill-tokens";
import LoginPage from "pages/login-page";

import AboutUs from "pages/about-us";
import ContactUs from "pages/contact-us";
import PrivacyPolicy from "pages/privacy-policy";
import TermsAndConditions from "pages/terms-and-conditions";
import Enquiry from "pages/enquiry";
import Dashboard from "pages/dashboard";
import Thankyou from "pages/thankyou";
import OrganicRegistration from "pages/organic-registration";
import ValidateCertificate from "pages/validate-certificate";
import Jobs from "pages/jobs";
import Signup from "pages/sign-up";
import Registration from "pages/registration";

export default function App() {
  useEffect(() => {
    ReactPixel.track("Purchase", { value: 142.52, currency: "USD" });
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<WebLayout />}>
          <Route index element={<MainPage />} />
          <Route
            exact
            path="/organic-registration"
            element={<OrganicRegistration />}
          ></Route>
          <Route
            exact
            path="/registration"
            element={<Registration />}
          ></Route>
          <Route exact path="/courses" element={<CoursesPage />} />
          <Route exact path="/staas" element={<SkillTokens />} />
          <Route exact path="/about-us" element={<AboutUs />} />  
          <Route exact path="/job" element={<Jobs />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            exact
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route exact path="/dashboard" element={<Dashboard />} />
        </Route>
        <Route exact path="/thank_you_" element={<Thankyou />}></Route>
        <Route exact path="/enquiry" element={<Enquiry />} />
        <Route
          exact
          path="/validate-certificate"
          element={<ValidateCertificate />}
        ></Route>
        <Route exact path="/login" element={<LoginPage />} />
        <Route exact path="/signup" element={<Signup />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </>
  );
}
