import axios, { AxiosError } from 'axios';
import store from 'store';
import lodash from 'lodash';

// export const endpoint = 'http://127.0.0.1:7000/api/v1';
// export const endpoint = "http://13.233.72.79/api/v1"
export const endpoint = "https://blockskillo.com/api/v1"
const instance = axios.create({
    baseURL: endpoint,
    // timeout: 5000
});

export const errorMessage = (error) => {
    const defaultErrorMessage = "Something went wrong";
    const apiError = error instanceof AxiosError
        ? lodash.get(error, 'response.data.message', defaultErrorMessage)
        : defaultErrorMessage;

    return apiError;
};

instance.interceptors.request.use((config) => {

    const accessToken = store.get('token');
    const publicRoutes = ["/login", "/signup", "enquiry"]

    if (lodash.some(publicRoutes, (url) => { return config.url.includes(url); })) { return config; }
    if (lodash.isEmpty(accessToken)) {
        throw new Error('No token available');
    }

    config.headers.Authorization = `Bearer ${accessToken}`;

    return config;
});

export default instance;
