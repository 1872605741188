import React from "react";
import { Link } from "react-router-dom";

import "./footer.css";
import {

  footerCompanyRegistry,
} from "registry/global-registry";
import Insta from "../assets/images/insta.svg";
import Linked from "../assets/images/linkedin.svg";
import Twit from "../assets/images/twitter.svg";
import Face from "../assets/images/facebook.svg";
import Logofoot from "assets/images/footLogo.png"

export default function Footer() {
  const year = new Date().getFullYear();
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-12 footer-media">
            <img src={Logofoot} alt="logo" loading="lazy" />
            <div
              className="footContent Know-more mt-1"
              style={{ textAlign: "left" }}
            >
              <ul>
                {/* {footerKnowMoreRegistry.map((list) => {
                  return (
                    <li key={list.id}>
                      <Link to={list.path}>{list.title}</Link>
                    </li>
                  );
                })} */}
                <li>
                  <Link to="/about-us">About Us</Link>
                </li>
                <li>
                  <Link to="/courses">Courses</Link>
                </li>
                <li>
                  <Link to="/staas">STAAS</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-8 col-12 footer-data">
            <div className="row d-flex justify-content-center">
              <div className="col-6 Know-more">
                <h3 className="title">Know More</h3>
                <ul>
                  {footerCompanyRegistry.map((list) => {
                    return (
                      <li key={list.id}>
                        <Link to={list.path}>{list.title}</Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="col-6 footer-company ">
                <h3 className="title">Follow us on</h3>
                <div className="socialIcon">
                  <div className="sIcon">
                    <a
                      href="https://www.instagram.com/blockskillo/"
                      className="btn btn-group"
                    >
                      <img src={Insta} />
                    </a>
                    <a href="https://www.linkedin.com/in/blockskillo" className="btn btn-group">
                      <img src={Linked} />
                    </a>
                  </div>
                  <div className="sIcon">
                    <a href="https://twitter.com/SnehalBhat35083" className="btn btn-group">
                      <img src={Twit} />
                    </a>
                    <a
                      href="https://www.facebook.com/profile.php?id=100090828846341"
                      className="btn btn-group"
                    >
                      <img src={Face} />
                    </a>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="col-12">
            <p className="text-white text-center web">Copyright@{year}BlockskilloEducationtechnologiespvtltd</p>
            <p className="text-white text-center mob">Copyright@{year}Blockskillo<br></br>Educationtechnologiespvtltd</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
