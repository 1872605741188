import React from 'react';
import { Outlet } from 'react-router-dom';
import { Navigate } from "react-router-dom";

import store  from 'store';
import lodash from 'lodash';

const PrivateRoutes = () => { 
    if (lodash.isEmpty(store.get('token'))) { 
      return (<React.Fragment><Navigate to="/login" /></React.Fragment>); 
    }
    return (<React.Fragment><Outlet /></React.Fragment>);
};

export default PrivateRoutes;
