import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import moment from 'moment'

const MyDataTable = (props) => {
    const data    = props.data ?? []
    const courseDetails = props.courseDetails ?? {}

    const columns = [{
        name: 'NFT CERTIFICATE CODE',
        selector: (row) => row.certificateCode,
      },{
          name: 'FIRST NAME',
          selector: (row) => row.firstName,
      },{
          name: 'SECOND NAME',
          selector: (row) => row.lastName,
      },{
          name: 'SKILL TOKEN SCORE',
          selector: (row) => row.completedSkill,
      },{
          name: 'OUT OF TOTAL',
          selector: (row) => row.totalSkill,
      },{
          name: 'START DATE',
          selector: (row) => moment(row.certificateDate).format('DD-MM-YYYY'),
      },{
          name: 'Contact Number',
          selector: (row) => row.cellPhone,
      },{
        name: 'EMAIL',
        selector: (row) => row.email,
    }];

    const [selectedRows,setSelectedRows] = useState([]);
    const handleSelection = (props) => { setSelectedRows(props.selectedRows);}
    // console.log('selectedRows',selectedRows);
  return (
    <React.Fragment>
      <div className="data-heading w-100">
          <span>{courseDetails.title} <br/> {courseDetails.level}</span>
      </div>
      <div className="even-color">
          <DataTable pagination progressPending={props.loading} selectedRows={selectedRows} columns={columns} data={data} selectableRows onSelectedRowsChange={handleSelection}></DataTable>
          {/* <h3>Selected Rows:</h3> */}
          {/* <pre style={{ color: 'white' }}>{JSON.stringify(selectedRows, null, 2)}</pre> */}
      </div>
    </React.Fragment>
);};

export default MyDataTable;