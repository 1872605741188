export const skillsRegistry = [
  {
    id: 1,
    title: 'Building a Blockchain using JavaScript.',
    img: '/images/skills/Blockchain.png',
  },
  {
    id: 2,
    title: 'Generating Bitcoin wallets and analysing Bitcoin transactions.',
    img: '/images/skills/Bitcoin.png',
  },
  {
    id: 3,
    title: 'Compiling and deploying Smart contracts on Ethereum.',
    img: '/images/skills/Ethereum.png',
  },
];

export const certificateRegistry = [
  {
    id: 1,
    img: '/images/certificate/certificate-nft-design.png',
    name: 'NFT Certificate',
  },
  {
    id: 2,
    img: '/images/certificate/certification-one.png',
    name: 'Skill-token Scorecard Level 1',
  },
  {
    id: 3,
    img: '/images/certificate/certification-two.png',
    name: 'Skill-token Scorecard Level 2',
  },
];

export const coursesRegistry = [
  {
    id: 1,
    img: '/images/why-us/industryLed.png',
    title: 'Industry-Led Skill Development',
    description: `Highest level of Industry oriented skill-development, 
      since the course structure and the content has been developed by Industry Practitioners.`,
  },
  {
    id: 2,
    img: '/images/why-us/rigorousCapability.png',
    title: 'Rigorous Capability Development',
    description: `Ours is a rigorous capability development model. It
                          tests theory, practicals as well as projects. This is
                          to ensure that the “graduates” are ready for
                          production from Day 1.`,
  },
  {
    id: 3,
    img: '/images/why-us/softSkill.png',
    title: 'Soft-skill & Blockchain Development',
    description: ` We have soft-skills development courses along with
                          developing the Blockchain capability. Technical
                          capability is incomplete without softskills. We are
                          the only ones offering courses on both the
                          capabilities.`,
  },
  {
    id: 4,
    img: '/images/why-us/fullStack.png',
    title: 'Full-stack Blockchain Development',
    description: `We cover development of user-interface. We are
                          developing front-ending skills. We are the only ones
                          offering full-stack Blockchain development skills.`,
  },
  {
    id: 5,
    img: '/images/why-us/hindiBlockchain.png',
    title: 'Hindi Blockchain Development',
    description: `We are the only ones offering a fully-blended
                          Blockchain development course in Hindi. Where even the
                          live sessions will be delivered in Hindi.`,
  },
  {
    id: 6,
    img: '/images/why-us/javaScriptMastery.png',
    title: 'JavaScript Mastery',
    description: `We have courses on Javascript and Nodejs. This means
                          almost any person; any under-graduate or graduate can
                          join the course and get a job.`,
  },
  {
    id: 7,
    img: '/images/why-us/jobPlacementAssistance.png',
    title: 'Job Placement Assistance',
    description: `Job placement cell for enabling jobs, post
                        certification.`,
  },
];
