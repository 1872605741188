import React from 'react';

import styled from 'styled-components';
import What from "../assets/images/whatsapp.png"

const WrapperContainer = styled.div`
  position: fixed;
  right: 45px;
  bottom: 40px;
  z-index: 1200;
  img {
    width: 50px;
    height: auto;
  }
  @media (max-width: 768px) {
    right: 20px;
    bottom: 25px;
  }
`;

const WhatsApp = () => {
  return (
    <>
      <WrapperContainer>
        <a href="https://api.whatsapp.com/send?phone=9016070140">
          {/* <img src="/images/whatsapp.png" alt="ws" loading="lazy" /> */}
          <img src={What} alt="ws" loading="lazy" />
        </a>
      </WrapperContainer>
    </>
  );
};

export default WhatsApp;
