import React, { useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import "./header.css";
import { navbarRegistry } from "registry/global-registry";
import Button from "./Button";
import Logo from "assets/images/logo.png"

export default function Header() {
  const location = useLocation();
  const handleHeader = useRef();
  const [scroll, setScroll] = useState(false);

  function handleToggleMenu() {
    handleHeader.current.classList.toggle("active");
  }

  function handleScroll() {
    if (window.scrollY >= 300) {
      document.body.classList.add("sticky");
      setScroll(true);
    } else {
      document.body.classList.remove("sticky");
      setScroll(false);
    }
  }

  window.addEventListener("scroll", handleScroll);

  return (
    <header
      className={scroll === true ? "header sticky" : "header"}
      ref={handleHeader}
    >
      <div className="logo_section">
        <Link to="/" className="logo_link">
          <img src={Logo} alt="logo" loading="lazy" />
          {/* <span>Blockskillo</span> */}
        </Link>
      </div>
      <nav className="navbar ">
        <ul className="navbar-list">
          {navbarRegistry.map((list) => {
            return (
              <li key={list.id}>
                <Link
                  to={list.path}
                  onClick={handleToggleMenu}
                  className={
                    location.pathname === list.path
                      ? "navbar-link active-nav"
                      : "navbar-link"
                  }
                >
                  {list.name}
                </Link>
              </li>
            );
          })}
          <li>
            <Link
              to="/login"
              onClick={handleToggleMenu}
              className="navbar-link"
            >
              <Button
                className="PrimaryBtn"
                padding="0.5rem 3rem"
                weight="700"
                size="1.01rem"
                borderRadius="50px"
                onClick={handleToggleMenu}
              >
                Login
              </Button>
            </Link>
          </li>
          <li>
            <Link
              to="/signup"
              // onClick={handleToggleMenu}
              className="navbar-link"
            >
              <Button
                className="PrimaryBtn"
                padding="0.5rem 3rem"
                weight="700"
                size="1.01rem"
                borderRadius="50px"
                // onClick={handleToggleMenu}
              >
                Sign Up
              </Button>
            </Link>
          </li>
        </ul>
      </nav>
      <div className="mobile-navbar-btn">
        <ion-icon
          class="mobile-nav-icon"
          style={{ color: "#000" }}
          name="menu-outline"
          onClick={handleToggleMenu}
        ></ion-icon>
        <ion-icon
          style={{ color: "#000" }}
          class="mobile-nav-icon"
          name="close-outline"
          onClick={handleToggleMenu}
        ></ion-icon>
      </div>
    </header>
  );
}
