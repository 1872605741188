import React from "react";

function Jobscard() {
  return (
    <div className="jobDetail">
      <div className="container">
        <div className="headVideo">
          <span>Jobs in Blockchain/Web3</span>
        </div>
        <p>
          Web3-related industries like Fintech, Edtech, Real Estate,
          Hospitality, and more are poised to hire in huge numbers in the
          following application areas. Candidates with a combination of
          technical skills related to Blockchain, along with an understanding of
          the specific industry&apos;s challenges and regulations, will be the most
          effective in driving successful Blockchain-related projects in these
          sectors.
        </p>
        <section id="finance">
          <div className="jobCards">
            <div className="jobHead">
              <h5>1. FINANCIAL SERVICES</h5>
            </div>
            <ul>
              <li>
                <span>Blockchain Expertise</span>: Seek candidates with a deep
                understanding of different Blockchain platforms, consensus
                mechanisms, and smart contract development.
              </li>
              <li>
                <span>DeFi Knowledge</span>: DeFi is a growing sector;
                candidates with expertise in decentralized finance protocols,
                yield farming, and liquidity provision are valuable.
              </li>
              <li>
                <span>Regulatory Awareness</span>: Financial services are
                heavily regulated; look for candidates who can navigate
                compliance and security considerations.
              </li>
              <li>
                <span>Tokenization Skills</span>: Candidates familiar with token
                standards (e.g., ERC-20, ERC-721) and their applications in
                asset tokenization are beneficial.
              </li>
            </ul>
          </div>
        </section>
        <section id="gaming">
          <div className="jobCards">
            <div className="jobHead">
              <h5>2. GAMING & ENTERTAINMENT</h5>
            </div>
            <ul>
              <li>
                <span>NFT Expertise</span>: Look for candidates who understand
                NFT standards and their applications in tokenizing in-game
                assets, digital art, and collectibles.
              </li>
              <li>
                <span>Decentralized Marketplaces</span>: Candidates should be
                capable of developing platforms for direct transactions between
                creators and consumers.
              </li>
              <li>
                <span>Blockchain Gaming</span>: Consider candidates with
                experience in designing games that leverage Blockchain
                technology for unique gameplay features.
              </li>
            </ul>
          </div>
        </section>
        <section id="managment">
          <div className="jobCards">
            <div className="jobHead">
              <h5>3. SUPPLY CHAIN MANAGEMENT</h5>
            </div>
            <ul>
              <li>
                <span>Supply Chain Integration</span>: Candidates should
                understand how to integrate Blockchain for end-to-end
                traceability and transparency in supply chains.
              </li>
              <li>
                <span>Smart Contract Development</span>: Knowledge of creating
                smart contracts to automate supply chain processes, like
                tracking shipments and verifying authenticity, is essential.
              </li>
              <li>
                <span>Interoperability</span>: Consider candidates who can
                design systems that connect different supply chain partners on a
                common Blockchain framework.
              </li>
            </ul>
          </div>
        </section>
        <section id="healthcare">
          <div className="jobCards">
            <div className="jobHead">
              <h5>4. HEALTHCARE</h5>
            </div>
            <ul>
              <li>
                <span>Health Data Privacy</span>: Candidates should be aware of
                regulations like HIPAA and GDPR and how Blockchain can provide
                secure and transparent sharing of health data.
              </li>
              <li>
                <span>Identity Solutions</span>: Knowledge of decentralized
                identity (DID) systems can help create secure and
                user-controlled patient identity management.
              </li>
              <li>
                <span>Healthcare Record Management</span>: Look for candidates
                with expertise in building systems that securely store and share
                electronic health records using Blockchain.
              </li>
            </ul>
          </div>
        </section>
        <section id="estate">
          <div className="jobCards">
            <div className="jobHead">
              <h5>5. REAL ESTATE</h5>
            </div>
            <ul>
              <li>
                <span>Property Tokenization</span>: Candidates should understand
                how to tokenize real estate assets and develop platforms for
                fractional ownership and trading.
              </li>
              <li>
                <span>Smart Contracts for Transactions</span>: Seek candidates
                with experience in creating smart contracts that automate real
                estate transactions, reducing paperwork and intermediaries.
              </li>
              <li>
                <span>Regulatory Understanding</span>: Real estate is regulated;
                candidates who can align Blockchain solutions with legal
                requirements are valuable.
              </li>
            </ul>
          </div>
        </section>
        <section id="agriculture">
          <div className="jobCards">
            <div className="jobHead">
              <h5>6. AGRICULTURE</h5>
            </div>
            <ul>
              <li>
                <span>Food Traceability Solutions</span>: Look for candidates
                who can develop platforms for tracking the journey of
                agricultural products from farm to table using Blockchain.
              </li>
              <li>
                <span>Supply Chain Efficiency</span>: Candidates should be
                capable of designing systems that streamline agricultural supply
                chains by reducing intermediaries.
              </li>
              <li>
                <span>Interoperability</span>: Consider candidates who can
                create Blockchain systems that connect different stakeholders in
                the agricultural sector.
              </li>
            </ul>
          </div>
        </section>
        <section id="voting">
          <div className="jobCards">
            <div className="jobHead">
              <h5>7. VOTING & GOVERNANCE</h5>
            </div>
            <ul>
              <li>
                <span>Food Traceability Solutions</span>: Look for candidates
                who can develop platforms for tracking the journey of
                agricultural products from farm to table using Blockchain.
              </li>
              <li>
                <span>Supply Chain Efficiency</span>: Candidates should be
                capable of designing systems that streamline agricultural supply
                chains by reducing intermediaries.
              </li>
              <li>
                <span>Interoperability</span>: Consider candidates who can
                create Blockchain systems that connect different stakeholders in
                the agricultural sector.
              </li>
            </ul>
          </div>
        </section>
        <section id="retail">
          <div className="jobCards">
            <div className="jobHead">
              <h5>8. RETAIL & E-COMMERCE</h5>
            </div>
            <ul>
              <li>
                <span>Supply Chain Transparency</span>: Candidates familiar with
                implementing Blockchain for transparent supply chains and
                verifying product authenticity are ideal.
              </li>
              <li>
                <span>Tokenized Loyalty Programs</span>: Seek candidates who can
                design tokenized loyalty rewards programs to enhance customer
                engagement and retention.
              </li>
              <li>
                <span>NFT Applications</span>: Candidates knowledgeable about
                NFTs can help retail and e-commerce businesses explore new ways
                to engage customers.
              </li>
            </ul>
          </div>
        </section>
        <section id="energy">
          <div className="jobCards">
            <div className="jobHead">
              <h5>9. Energy and Utilities</h5>
            </div>
            <ul>
              <li>
                <span>Energy Trading Knowledge</span>: Candidates should grasp
                the concept of peer-to-peer energy trading using Blockchain and
                its impact on the energy sector.
              </li>
              <li>
                <span>Decentralized Grid Management:</span>: Look for candidates
                who can design solutions to enhance the efficiency and
                reliability of energy distribution grids.
              </li>
              <li>
                <span>Interoperable Systems</span>: Consider candidates who can
                develop systems that allow different energy sources and
                providers to interact seamlessly.
              </li>
            </ul>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Jobscard;
