import React, { useState, useEffect } from 'react';
import lodash from 'lodash'
import { apiGetScore } from 'api/skills';

import 'styles/nft.css'
import MountModule from 'modules/mount.module';
import moment from 'moment';

const certificateStyle = { backgroundImage: `url(${process.env.PUBLIC_URL + '/images/nft/certificate-nft.png'  })`}
const scoreCardStyle   = { backgroundImage: `url(${process.env.PUBLIC_URL + '/images/nft/certificate-score.png'})`}

const Certificate = (props) => {
    const userDetails    = props.userDetails ?? {};
    const courseDetails = props.courseDetails ?? {}
    const [content, updateContent] = useState('score-card');
    const [scores, setScores] = useState([]);
    const [tokensDetails, updateTokenDetails] = useState({ countToken : 0, countTotalToken : 0 });

    const fetchScore = async (courseId) => {
      const { status, data } = await apiGetScore({ courseId });

      if(status === true) {
          const details = lodash.reduce(data.results,(result, item) => {
                result.countToken      += item.token;
                result.countTotalToken += item.totalToken;
              return result;
          },{ countToken: 0, countTotalToken: 0 });

          setScores(data.results); updateTokenDetails({ ...details })
      }
  };

  useEffect(() => {
    courseDetails.courseId && fetchScore(courseDetails.courseId);
  }, [props.courseDetails]);

  return (
    <React.Fragment>
        <div className="nft_certificate_modal_body">
          <MountModule condition={content === 'certificate'}>
              <div className={`nft_certificate_modal_bg_certificate  text-center`} style={certificateStyle}>
                  <button className={`btn nft_certificate_btn`} onClick={() => { updateContent('score-card') }}>
                    <span>SKILL-TOKEN SCORECARD</span>
                  </button>
                  <div className="nft_certificate_name_section_1">
                      <h3>{courseDetails.orgName}</h3>
                      <p>Congratulations on completing the Certificate <br />program on Blockchain Technology</p>
                      <div><h4>{userDetails.firstName + ' ' + userDetails.lastName}</h4></div>
                  </div>
                <div className="nft_certificate_name_section_2">
                  <p>Date : {moment(courseDetails.certificateDate).format('DD-MM-YYYY')}</p>
                  <p className="pt-2">Digitally signed</p>
                  <h4>NFT: {courseDetails.certificateCode}</h4>
                  <div>{courseDetails.orgDomain}</div>
                </div>
              </div>
          </MountModule>
              
          <MountModule condition={content === 'score-card'}>
              <div className={`nft_certificate_modal_bg_score text-center`} style={scoreCardStyle}>
                  <div className="nft_modal_heading_1">
                    <span></span>
                    <span>{String(courseDetails.course?.level ?? '').toUpperCase()}</span><span className="certificate-date">Date:  {moment(courseDetails.certificateDate).format('DD-MM-YYYY')}</span>
                  </div>
                    <div className="certificate-name-code">
                          <p>Name: {userDetails.firstName + ' ' + userDetails.lastName}</p>
                          <p>NFT Code: {courseDetails.certificateCode}</p>
                    </div>
                  <div>
                      <table className="table nft_modal_score_table">
                          <thead>
                              <tr>
                                  <th scope="col" style={{ width: '15%' }}>SKILL NO.</th>
                                  <th scope="col" style={{ width: '40%' }}>SKILL TITLE</th>
                                  <th scope="col" style={{ width: '15%' }}>Proficiency</th>
                                  <th scope="col" style={{ width: '15%' }}>SKILL-TOKEN SCORE</th>
                                  <th scope="col" style={{ width: '15%' }}>OUT OF TOTAL</th>
                              </tr>
                          </thead>
                          <tbody>
                          <MountModule condition={lodash.isEmpty(scores)}>
                              <tr>
                                <td colSpan={5} className="text-center pt-3"><h4>Score not available!</h4></td>
                              </tr>
                          </MountModule>
                          <MountModule condition={!lodash.isEmpty(scores)}>
                              {scores.map((score, index) => { return (
                                  <tr key={score.id}>
                                      <td>{index + 1}</td>
                                      <td className="text-left">{score.skill.title}</td>
                                      <td>{score.profeciency}</td>
                                      <td>{score.token}</td>
                                      <td>{score.totalToken}</td>
                                  </tr>
                              );})}
                              <tr className="nft_modal_score_table_last_col">
                                  <td scope="row"></td>
                                  <td className="text-left">TOTAL TOKENS</td>
                                  <td></td>
                                  <td>{tokensDetails.countToken}</td>
                                  <td>{tokensDetails.countTotalToken}</td>
                              </tr>
                          </MountModule>
                      </tbody>
                    </table>
                  </div>
                  <MountModule condition={!lodash.isEmpty(scores)}>
                      <button className={`btn nft_certificate_btn`} onClick={() => { updateContent('certificate') }}>
                        <span>CERTIFICATE</span>
                      </button>
                  </MountModule>
              </div>
          </MountModule>
        </div>
    </React.Fragment>
  );
};

export default React.memo(Certificate);