import instance from "api";

export const apiCourses = async (params) => {
    try {
        const response = await instance.get('course/enrollment',params);
        return { status: true, data : response.data };
    } catch (error) { return { status: false, error: error.message }; }   
};

export const apiAdminCourses = async (params) => {
    try {
        const response = await instance.get('course', { params });
        return { status: true, data : response.data };
    } catch (error) { return { status: false, error: error.message }; }   
};

export const apiEnrolledStudents = async (courseId) => {
    try {
        const response = await instance.get(`course/enrolled-students`,{ params : { courseId }});
        return { status: true, data : response.data };
    } catch (error) { return { status: false, error: error.message }; }   
};