import React from 'react';

import 'styles/main-page.css';
import HeroSection from '../components/HeroSection';
import SkillsSection from '../components/SkillsSection';
import WhyUsSection from '../components/WhyUsSection';
import HiringPartner from '../components/HiringPartner';

export default function MainPage() {
  return (
    <>
      <HeroSection />
      <SkillsSection />
      <WhyUsSection />
      <HiringPartner />
    </>
  );
}
