import React from 'react'
import Mount from 'modules/mount.module.jsx'
import { endpoint } from 'api/index'
const CourseCard = (props) => {

    const data = props.data ?? { course: {} }
    const downloadUrl = `${endpoint}/certificate/download?certificateCode=${data.certificateCode}`;
    const handleView = () => { props.handleCertificate(data); }

    return (
        <div className="col-lg-4 col-md-6 col-sm-12"><div className="dashCardBoundary">
            <div className="dashCourseName">
                <span>{data.course.title}</span>
                <span>{data.course.level}</span>
            </div>
            <div className="dashCourseButtons">
                <button onClick={handleView} className="dashView">View</button>
                <Mount condition={data.isCertificate}>
                    <a className="dashDownload" style={{ marginLeft: '1.5em' }} href={downloadUrl} download>Download File</a>
                </Mount>
            </div>
        </div></div>
    );
}

export default React.memo(CourseCard)
