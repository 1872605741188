import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Card from '../components/Card';

const AWS_URL = 'https://blockskillo-content.s3.ap-south-1.amazonaws.com';

const Wrapper100X = styled.span`
  color: #4cccf1;
`;

const WrapperTopP1 = styled.p`
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
`;

const WrapperTopP2 = styled.p`
  font-weight: 600;
  font-size: 27px;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: -74px;
  @media (max-width: 800px) {
    margin-top: 20px !important;
    text-align: center;
  }
`;

const WrapperTopP2Span = styled.span`
  color: #0cb7ee;
`;

const WrapperCard = styled.div`
  width: 210px;
  height: 135px;
  padding: 20px 5px;
  margin: auto;
  background-image: url(${'/images/card-border.svg'});
  background-repeat: no-repeat;
`;

const WrapperCounter = styled.div`
  background-color: var(--text-secondary);
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 25px;
  color: #fff;
  margin: auto;
  font-size: 16px;
`;

const WrapperCardText = styled.p`
  font-weight: 400;
  font-size: 19px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  margin-top: 5px;
`;

const BottomText1 = styled.a`
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: #ffffff;
  }
`;

const BottomText2 = styled.a`
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #0cb7ee;
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: #0cb7ee;
  }
`;

const AboutUs = () => {
  const navigate = useNavigate()
  return (
    <>
      <section className="section section-heroo pt-2 pb-3">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-lg-7 col-md-7  col-12 section-hero-data">
              <div
                className="d-flex justify-content-center flex-column 
                     text-start"
              >
                <h1>
                  STAAS will enable Education <br /> Businesses <Wrapper100X>100X</Wrapper100X>{' '}
                  growth
                </h1>
                <WrapperTopP1>Because credibility is everything</WrapperTopP1>
              </div>
            </div>
            <div
              className="col-lg-5 col-md-5 col-12
             section-hero-slider mt-lg-0 mt-md-0 mt-5"
            >
              <div className="ml-auto" style={{ maxWidth: '360px', padding: '20px' }}>
                <Card
                  className=" section-skills-card 
                    d-flex align-items-center justify-content-center
                     flex-column text-center p-4"
                >
                  <div className="section-hero-content text-center">
                    <h3 style={{ fontSize: '20px' }}>NFT CERTIFICATE</h3>
                    <div className="image-flip-certificate-container">
                      <div className="image-flip-card shadow">
                        <div className="front image-flip-face">
                          <img
                            className="certificate-image"
                            src="/images/certificate/certificate-nft-design.png"
                            alt="nft"
                            loading="lazy"
                            decoding="async"
                          />
                        </div>
                        <div className="back image-flip-face">
                          <img
                            className="certificate-image"
                            src="/images/certificate/certification-one.png"
                            alt="nft"
                            loading="lazy"
                            decoding="async"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12">
              <WrapperTopP2>
                Skill tokenization as a service <WrapperTopP2Span>(STAAS)</WrapperTopP2Span> -
              </WrapperTopP2>
            </div>
          </div>

          <div className="row row-cols-sm-5 row-cols-md-5 row-cols-lg-5 mt-4 mb-5 mt-md-0 mb-md-0">
            <div className="col col-xs-12 align-items-center mb-3">
              <WrapperCard>
                <div className="d-flex align-items-center">
                  <WrapperCounter>1</WrapperCounter>
                </div>
                <WrapperCardText>
                  Tamperproof <br />
                  Certificates
                </WrapperCardText>
              </WrapperCard>
            </div>
            <div className="col col-xs-12 align-items-center mb-3">
              <WrapperCard onClick={() => { navigate('/validate-certificate') }} style={{ cursor: 'pointer' }}>
                <div className="d-flex align-items-center">
                  <WrapperCounter>2</WrapperCounter>
                </div>
                <WrapperCardText>
                  Instant Validation <br />
                  of Certificates
                </WrapperCardText>
              </WrapperCard>
            </div>
            <div className="col col-xs-12 align-items-center mb-3">
              <WrapperCard>
                <div className="d-flex align-items-center">
                  <WrapperCounter>3</WrapperCounter>
                </div>
                <WrapperCardText>
                  Predictability in <br /> performance
                </WrapperCardText>
              </WrapperCard>
            </div>
            <div className="col col-xs-12 align-items-center mb-3">
              <WrapperCard>
                <div className="d-flex align-items-center">
                  <WrapperCounter>4</WrapperCounter>
                </div>
                <WrapperCardText>
                  Objective <br />
                  hiring
                </WrapperCardText>
              </WrapperCard>
            </div>
            <div className="col col-xs-12 align-items-center mb-3">
              <WrapperCard>
                <div className="d-flex align-items-center">
                  <WrapperCounter>5</WrapperCounter>
                </div>
                <WrapperCardText>
                  99% lower <br />
                  carbon footprint
                </WrapperCardText>
              </WrapperCard>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-md-5 align-self-center text-center">
              <BottomText1
              style={{borderBottom:"1px solid #fff"}}
                href="https://docs.google.com/forms/d/1WVyikO4eAhCzS6_zQEVaCVqRizZXVGtPp2g_zjwa9Ms"
                target="_blank"
              >
                Click Here, For Free Consulting
              </BottomText1>
            </div>
            <div className="col-md-2 align-self-center text-center">
              <div className="image-flip-coin-container">
                <div className="image-flip-card shadow">
                  <div className="front image-flip-face">
                    <img
                      className="coin-image"
                      src="/images/coin/coin-1.png"
                      alt=""
                      loading="lazy"
                      decoding="async"
                    />
                  </div>
                  <div className="back image-flip-face">
                    <img
                      className="coin-image"
                      src="/images/coin/coin-2.png"
                      alt=""
                      loading="lazy"
                      decoding="async"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 align-self-center text-center">
              <BottomText2
              style={{color:"#fff",borderBottom:"1px solid #fff"}}
                href={`${AWS_URL}/skill-tokenization-v1.pdf`}
                rel="noopener noreferrer"
                target="_blank"
              >
                Benefits of Skill Tokenization
              </BottomText2>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
