import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { apiSendEnquiry } from 'api/enquiry';
import * as yup from 'yup';
import 'styles/enquiry.css';

import logo     from 'assets/images/blocks-killo-logo.png';
import tokenOne from 'assets/images/blocks-killo-token-1.png';
import tokenTwo from 'assets/images/blocks-killo-token-2.png';
import { toast, ToastContainer } from 'react-toastify';

const Enquiry = () => {
    const validationSchema = yup.object().shape({
        enquiryFullName: yup.string()
          .required('Full name is required.'),
        enquiryEmail: yup.string()
          .required('Email is required.')
          .email('Please enter a valid Email address.'),
        enquiryWhatsappNumber: yup.string()
          .required('Contact number is required.'),
      });

      const initialValues = {
        enquiryFullName: '',
        enquiryEmail: '',
        enquiryWhatsappNumber: '',
      };

      const handleSubmit = async(values, {resetForm})=>{
          const { status, error } = await apiSendEnquiry(values);

          if(status === false) { return toast.error(error); }

          toast.success("Enquiry Submitted successfully!");
          
          resetForm({
              enquiryFullName: '',
              enquiryEmail: '',
              enquiryWhatsappNumber: '',
          });
      };
    
  return (
    <section className=""> 
      <div className="container mainBackgroundss">
        <div className="nft-page-container">
            <div className="nft-page-row row">
              <div className="nft-page-column col-lg-6 col-md-6 col-sm-12">
                <div className="nft-column-box">
                   <div className="nft-image-top">
                    <img src={tokenOne} alt="token-one" />
                   </div>
                   <div className="nft-content-bottom">
                        <div className="nft-content-gradient">
                            <ul>
                                <li>100% Job Assistance</li>
                                <li>Free interview prep course*</li>
                                <li>Free Startup funding course* Designed and delivered by an  Angel Investor)</li>
                                <li>Live session by Blockchain Developers</li>
                                <li>Mentoring on Projects by Experts</li>
                                <li>Earn skill-tokens in your own wallet, and get a unique NFT Certificate</li>
                                <li>Course in English as well as Hindi</li>
                                <span>*Free only for the first 50 students</span>
                            </ul>
                        </div>
                   </div>
                </div>
              </div>

              <div className="nft-page-column col-lg-6 col-md-6 col-sm-12">
                <div className="nft-column-box">
                  <div className="nft-content-top">
                      <div className="nft-content-gradient">
                            <p>Welcome to</p>
                            <img src={logo} alt="Logo" />

                            <div className="nft-content-form">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                                >
                                {({ values, handleChange }) => (
                                    <Form>
                                        <div  className="mb-0 form-section">
                                            <Field value={values.enquiryFullName} onChange={(event) => { handleChange(event)}}
                                             type="text" name="enquiryFullName" placeholder='Full Name'/>
                                            <ErrorMessage name="enquiryFullName" component="div" className="errorShow"/>
                                        </div>

                                        <div  className="mb-0 form-section">
                                            <Field value={values.enquiryEmail} onChange={(event) => { handleChange(event)}} 
                                            type="enquiryEmail" name="enquiryEmail" placeholder='Email Id' />
                                            <ErrorMessage name="enquiryEmail" component="div" className="errorShow"/>
                                        </div>

                                        <div  className="mb-0 form-section">
                                            <Field value={values.enquiryWhatsappNumber} onChange={(event) => { handleChange(event)}} 
                                            type="tel" name="enquiryWhatsappNumber" placeholder='Whatsapp No.' />
                                            <ErrorMessage name="enquiryWhatsappNumber" component="div" className="errorShow"/>
                                        </div>
                                        <button className='submit-button' type="submit">Submit</button>
                                    </Form>
                                )}
                             </Formik>
                            </div>
                      </div>
                  </div>
                  <div className="nft-image-bottom">
                    <img src={tokenTwo} alt="token-two" />
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      <ToastContainer></ToastContainer>
    </section>
  );
};

export default Enquiry;
