import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { apiUsers } from 'api/users';
import lodash from 'lodash';

const customStyles = {
    control: (provided) => ({
        ...provided,
        backgroundColor: 'black',
        borderColor: 'gray',
        width: '250px',
        borderRadius: "10px"
    }),
    option: (provided) => ({
        ...provided,
        backgroundColor: 'black',
        color: 'white',
        cursor: "pointer",
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'white',
    }), menu: (provided) => ({
        ...provided,
        backgroundColor: "black",
    }),
};
const SearchStudentComponent = (props) => {
    const [options, updateOptions] = useState([])

    const handleSelectChange = (option) => {
        if (!lodash.isNull(option)) { props.searchCourse({ userId: option.value }) }
        else { props.searchCourse({}) }
    };

    const searchUsers = async () => {
        const response = await apiUsers()
        if (response.status === true) {
            const users = lodash.filter(response.data.results, (data) => {
                return data.role === "user";
            }).map((data) => ({ value: data.id, label: `${data.firstName} ${data.lastName}` }));

            updateOptions(users);
        }
    }

    console.log('props', props);
    useEffect(() => { searchUsers() }, [])

    return (
        <React.Fragment>
            <Select isClearable options={options} onChange={handleSelectChange} styles={customStyles} />
        </React.Fragment>
    )
}

export default React.memo(SearchStudentComponent);
