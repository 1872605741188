import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';

import { skillsRegistry } from 'registry/main-page-registry';
import Card from './Card';

export default function SkillsSection() {
  return (
    <section className="section section-skills">
      <div className="container">
        <div className="section-skills-heading">
          <h3>Skill Focused</h3>
          <span className="para">
            We have soft-skills development courses along with developing the Blockchain capability.
            Technical capability is incomplete without softskills. We are the only ones offering
            courses on both the capabilities.
          </span>
        </div>
        <div className="mt-5 section-skills-list">
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            className="mySwiper"
          >
            {skillsRegistry.map((item) => {
              return (
                <SwiperSlide key={item.id}>
                  <Card
                    className="primaryCard section-skills-card 
                    d-flex align-items-center justify-content-center
                     flex-column text-center"
                    borderRadius="20px"
                    padding="1rem"
                    height="20rem"
                  >
                    <div className="section-skills-content">
                      <img src={item.img} alt={item.title} />
                      <p className="mt-4">
                        {item.id}. {''}
                        {item.title}
                      </p>
                    </div>
                  </Card>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </section>
  );
}
