import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import lodash from 'lodash';
import { apiValidateCertificate } from 'api/users';
import * as yup from 'yup';
import 'styles/enquiry.css';

import logo     from 'assets/images/blocks-killo-logo.png';
import tokenOne from 'assets/images/blocks-killo-token-1.png';
import tokenTwo from 'assets/images/blocks-killo-token-2.png';
import { toast, ToastContainer } from 'react-toastify';
import { MountSwitch } from 'modules/mount.module';

const ValidateCertificate = () => {
    const [userDetails,updateUserDetails] = useState({});
    const initialValues = { certificateCode : '', };
    const validationSchema = yup.object().shape({
        certificateCode: yup.string().required('Please enter certificate code.'),
    });

    const handleSubmit = async(values, {resetForm})=>{
      const response = await apiValidateCertificate(values);

      console.log(response.data);

      if(response.status === false) { return toast.error(response.error); }

      updateUserDetails({ userName : response.data.userName });
          
      return resetForm({ certificateCode: '', });
    };
    
  return (
    <section className="container"> 
      <div className="mainBackgroundss">
        <div className="nft-page-container">
            <div className="nft-page-row row">
              
              <div className="nft-page-column col-lg-3 col-md-6 col-sm-12"><div className="nft-column-box">
                   <div className="nft-image-top"><img src={tokenOne} alt="token-one" /></div>
              </div></div>

              <div className="nft-page-column col-lg-6 col-md-6 col-sm-12"><div className="nft-column-box new-nft-two">
                  <div className="nft-content-top"><div className="nft-content-gradient">
                        <p>Welcome to</p>
                        <img src={logo} alt="Logo" />
                        <MountSwitch condition={lodash.isEmpty(userDetails)}>
                            <MountSwitch.True>
                                <p className="new-certificate-code">Certificate Code</p>
                            </MountSwitch.True>
                            <MountSwitch.False>
                                <p className='new-certificate-holder'>Certificate Holder</p>
                            </MountSwitch.False>
                        </MountSwitch>
                        <div className="nft-content-form">
                            <MountSwitch condition={lodash.isEmpty(userDetails)}>
                                <MountSwitch.True>
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                        >
                                        {({ values, handleChange }) => (
                                            <Form>
                                                <div  className="form-section custom-form-section">
                                                    <Field value={values.certificateCode} onChange={(event) => { handleChange(event)}} name="certificateCode" placeholder='Enter Certificate Code' />
                                                </div>
                                                <ErrorMessage name="certificateCode" component="h1" className="mt-3 errorShow"/>
                                                <button className='new-certificate-code-submit text-white display-1' type="submit">Click here to validate</button>
                                            </Form>
                                        )}
                                    </Formik>
                                  </MountSwitch.True>
                                  <MountSwitch.False>
                                      <div  className="form-section custom-form-section">
                                          <div className='certificate-holder-name'>{userDetails.userName}</div>
                                      </div>
                                      <p className='new-certificate-holder mt-5 mb-3'>Certificate validated!</p>
                                      <button className='reset-certificate' onClick={() => { updateUserDetails({}) }}>Reset</button>
                                  </MountSwitch.False>
                            </MountSwitch>
                        </div>
                  </div></div>
                  <div className='d-flex justify-content-center mt-3'>
                      <p className='text-bold'>Contact: learn@blockskillo.com</p>
                  </div>
              </div></div>

              <div className="nft-page-column col-lg-3 col-md-6 col-sm-12 valid-certify-right-image">
                   <div className="nft-image-bottom">
                      <img src={tokenTwo} alt="token-two" />
                   </div>
              </div>
            </div>
        </div>
      </div>
      <ToastContainer autoClose={1000}></ToastContainer>
    </section>
  );
};

export default ValidateCertificate;
