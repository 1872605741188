import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import 'styles/index.css';
import 'styles/layout.css';
import 'styles/utils.css';
import 'swiper/css';
import App from './app';
import reportWebVitals from './reportWebVitals';
import ReactPixel from 'react-facebook-pixel';

const root = ReactDOM.createRoot(document.getElementById('root'));
const YOUR_PIXEL_ID = "797178485419021"

ReactPixel.init(YOUR_PIXEL_ID);
ReactPixel.init(YOUR_PIXEL_ID, null, { autoConfig: true, debug: false, });

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
