import React from 'react';
// import { Link } from 'react-router-dom';

import 'styles/privacy-policy.css';

const AboutUs = () => {
  return (
    <section className="about-us-section">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="heading-text-1 text-center mt-2"><span>About</span> Us</h2>
            <p className="subheading-text-1 mt-4">
              Our solution to increase employability is unique, due the blend of a variety of
              relevant benefits.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="heading-text-2 mt-5">
              Fastest growing technology domain globally {' '}
            </h3>
            <p className="mt-3">
              Blockchain is a new technology that is getting “viral” globally. Total size of the
              industry is slated to cross US$ 1000 Billion by 2030 (Current size is under US$ 6
              Billion). It is the fastest growing sub-domain within the technology umbrella  56%
              CAGR. The blockchain education is important because it helps people to understand how
              the technology works and what are the applications of this new technology. Blockchain
              education is necessary in order to understand and implement the technology. Blockchain
              helps people to make transactions that are fast, secure and transparent. As a result,
              blockchain can be used in many sectors including financial services, insurance,
              property management, supply chain management and healthcare among others. The
              blockchain education is necessary for those who want to understand how the technology
              works and make their companies more efficient. The blockchain is an online public
              ledger of all Bitcoin transactions. It is a decentralized peer-to-peer transaction
              database that is continuously growing as “completed” blocks are added to it with a new
              set of recordings. The blockchain uses highly secure cryptography and offers many
              advantages including less risk, lower costs and faster settlement than traditional
              systems. The blockchain education helps individuals as well as large and small
              organizations in finding solutions to some of their problems like providing solutions
              for the following:- Financial services - Insurance - Property management - Supply
              chain management- Healthcare. The use of this technology meets the growing global
              needs for decentralization and can be applied across domains.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="heading-text-2 mt-5">Modern principles of learning  </h3>
            <p className="mt-3">
              Our Founder has been the Capability Development Lead for large multinational
              organizations. He has been in the field of training-at-workplace for more than 20
              years now. The past experience of developing the learning assets and more has gone
              into the development of all the courses. The courses have been made to be effortful,
              contextual, engaging, interactive and with real-life projects. The capability
              development process deployed is the possibly the most rigorous and has been created
              with the view of making the passouts ready to meet and exceed the expectations of the
              Delivery and Project Managers working on global Blockchain projects.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="heading-text-2 mt-5">Coding skills  </h3>
            <p className="mt-3">
              Our latest National Education Policy has emphasized the need for coding skills across
              the spectrum of learning. Our courses are focussed on enabling the most basic coding
              skills. Our courses DO NOT need any previous coding experience. We inculcate the basic
              coding skills needed for the Participant. Also, one of the key differentiators is that
              we are launching our first course on Blockchain in Hindi along with English. This will
              ensure the vast rural reach for our country and be a strong step in the direction of
              increasing employment in the rural youth. We also plan to have our course in regional
              languages as well.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="heading-text-2 mt-5">Standard delivery of education  </h3>
            <p className="mt-3">
              The courses are delivered using the blended approach. This means that we will have
              online as well as live delivery classes. The online component will be delivered
              through videos. This ensures a standard content delivery approach to all the students.
              This also ensures that the content itself is also comprehensive and is standard across
              all sessions.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="heading-text-2 mt-5">Highly scalable delivery model  </h3>
            <p className="mt-3">
              We are using servers which will be scalable with minimal to zero development work for
              increasing the bandwidth of delivery. This means that even if the number of students
              taking courses increases to a large number, we will be able to ramp our infrastructure
              within a very short period of time, if not within a few hours.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="heading-text-2 mt-5">Affordable  </h3>
            <p className="mt-3 mb-5">
              Most equivalent technical professional courses cost way higher. Our cost of courses is
              the lowest in the market. The objective of the courses is to make it affordable,
              amongst others, for rural markets as well. We also qualify to be signed up with a
              variety of Education loan companies. This will make the courses within the reach of
              almost everyone, including those earning close to minimum wage levels.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
