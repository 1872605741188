import React from 'react';

import { coursesRegistry } from 'registry/main-page-registry';
import Card from './Card';

export default function WhyUsSection() {
  return (
    <section className="section section-why-us">
      <div className="container">
        <div className="section-why-us-heading">
          <h3>Why Us?</h3>
        </div>
        <div className="row mt-3 section-why-us-list">
          {coursesRegistry.map((item) => {
            return (
              <div key={item.id} className="col-lg-4 col-md-4 col-12">
                <Card
                  className="scecondaryCard section-why-us-card mt-5"
                  borderRadius="20px"
                  padding="0.3rem 1rem"
                  height="23rem"
                >
                  <div className="section-why-us-content">
                    <img src={item.img} alt={item.title} />
                    <div className="counter">
                      <p>{item.id}</p>
                    </div>
                    <h3 className="mt-4">{item.title}</h3>
                    <span className="para">{item.description}</span>
                  </div>
                </Card>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
