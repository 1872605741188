import instance from "api";

export const apiSendEnquiry = async (payload) => {
    try {
        const response = await instance.post('enquiry', payload);
        return { status: true, data : response.data };
    } catch (error) { return { status: false, error: error.message }; }   
};

export const apiGetEnquiries = async (params) => {
    try {
        const response = await instance.get('enquiry',{ params : { ...params }});
        return { status: true, data : response.data };
    } catch (error) { return { status: false, error: error.message }; }   
};
