import { useState } from "react";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "styles/video.css";

function VideoSection() {
  // const [show, setShow] = useState(false);
  const [change, setChange] = useState({ visible: false, hide: "english" });
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  return (
    <div className="container">
      <div className="videoSection">
        <div className="headVideo">
          <span>Videos</span>
          <h5>What is Blockchain technology all about?</h5>
        </div>
        <div className="row">
          <div className="col-md-6 pr-5 mobpadd">
            <div className="videoCards">
              <div className="headVideos">
                <span>English</span>
              </div>
              <div className="videocard">
                <div className="innerCard">
                  <a
                    className="btn btn-group"
                    onClick={() => {
                      setChange({ visible: true, hide: "english" });
                    }}
                  >
                    <svg
                      width="95"
                      height="147"
                      viewBox="0 0 95 147"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g filter="url(#filter0_d_531_775)">
                        <path
                          d="M85 63.2903L6 126V0.580645L85 63.2903Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_d_531_775"
                          x="0"
                          y="0"
                          width="95"
                          height="147"
                          filterUnits="userSpaceOnUse"
                          colorInterpolationFilters="sRGB"
                        >
                          <feFlood
                            floodOpacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dx="2" dy="13" />
                          <feGaussianBlur stdDeviation="4" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_531_775"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_531_775"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 pl-5 mobpadd">
            <div className="videoCards">
              <div className="headVideos">
                <span>Hindi</span>
              </div>
              <div className="videocard">
                <div className="innerCard">
                  <a
                    className="btn btn-group"
                    onClick={() => {
                      setChange({ visible: true, hide: "hindi" });
                    }}
                  >
                    <svg
                      width="95"
                      height="147"
                      viewBox="0 0 95 147"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g filter="url(#filter0_d_531_775)">
                        <path
                          d="M85 63.2903L6 126V0.580645L85 63.2903Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_d_531_775"
                          x="0"
                          y="0"
                          width="95"
                          height="147"
                          filterUnits="userSpaceOnUse"
                          colorInterpolationFilters="sRGB"
                        >
                          <feFlood
                            floodOpacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dx="2" dy="13" />
                          <feGaussianBlur stdDeviation="4" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_531_775"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_531_775"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="mainModal"
        show={change.visible}
        onHide={() => {
          setChange({ visible: false});
        }}
      >
        {/* <Modal.Header closeButton>
                <Modal.Title>Modal heading</Modal.Title>
              </Modal.Header> */}
        <Modal.Body>
          <iframe
            src={
              change.hide === "english"
                ? "https://player.vimeo.com/video/813988760?h=c34b52ba6c"
                : "https://player.vimeo.com/video/813989781?h=99119f5e4b"
            }
            width="100%"
            height="360"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Modal.Body>
        {/* <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleClose}>
                  Save Changes
                </Button>
              </Modal.Footer> */}
      </Modal>
    </div>
  );
}

export default VideoSection;
