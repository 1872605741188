import React from 'react';
// import { Link } from 'react-router-dom';

import 'styles/privacy-policy.css';

const PrivacyPolicy = () => {
  return (
    <section className="privacy-policy-section">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="heading-text-1 text-center mt-2">Privacy policy</h2>
            <p className="subheading-text-1 mt-5">
              Welcome to Blockskillo Education Technologies OPC Pvt. Ltd. (to be referred for the
              purposes of this document as Blockskillo)!
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <p className="mt-3">Effective date: 15-03-2023 </p>
            <p className="mt-3"> Updated on: 15-03-2023 </p>
            <p className="mt-3">
              This Privacy Policy explains the policies of Blockskillo on the collection and use of
              the information we collect when you access blockskillo.com (the “Service”). This
              Privacy Policy describes your privacy rights and how you are protected under privacy
              laws.
              <br /> <br />
              By using our Service, you are consenting to the collection and use of your information
              in accordance with this Privacy Policy. Please do not access or use our Service if you
              do not consent to the collection and use of your information as outlined in this
              Privacy Policy.
              <br /> <br />
              Blockskillo is authorized to modify this Privacy Policy at any time. This may occur
              without prior notice.
              <br /> <br />
              Blockskillo will post the revised Privacy Policy on the blockskillo.com website
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="heading-text-3 mt-5">Collection and Use of Your Personal Information</h3>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="heading-text-2 mt-5">Information We Collect</h3>
            <p className="mt-3">
              When using our Service, you will be prompted to provide us with personal information
              used to contact or identify you. blockskillo.com collects the following information:
              <br />
              <ul className="list-1 ml-5">
                <li>Usage Data</li>
                <li>Name</li>
                <li>Email</li>
                <li>Mobile Number</li>
              </ul>
            </p>
            <p className="mt-3">
              Usage Data includes the following:
              <br />
              <ul className="list-1 ml-5">
                <li>Internet Protocol (IP) address of computers accessing the site</li>
                <li>Web page requests</li>
                <li>Referring web pages</li>
                <li>Browser used to access site</li>
                <li>Time and date of access</li>
              </ul>
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="heading-text-2 mt-5">How We Collect Information</h3>
            <p className="mt-3">
              blockskillo.com collects and receives information from you in the following manner:
              <br /> <br />
              When you fill a registration form or otherwise submit your personal information. Your
              information will be stored for up to 30 days after it is no longer required to provide
              you the services. Your information may be retained for longer periods for reporting or
              record- keeping in accordance with applicable laws. Information which does not
              identify you personally may be stored indefinitely.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="heading-text-2 mt-5">How We Use Your Information</h3>
            <p className="mt-3">
              blockskillo.com may use your information for the following purposes:
              <br /> <br />
              <ul className="list-1 ml-5">
                <li>
                  <strong>Providing and maintaining our Service, </strong> as well as monitoring the
                  usage of our Service.
                </li>
                <li>
                  <strong>For other purposes. </strong> Blockskillo will use your information for
                  data analysis to identify usage trends or determine the effective of our marketing
                  campaigns when reasonable. We will use your information to evaluate and improve
                  our Service, products, services, and marketing efforts.
                </li>
                <li>
                  <strong>Managing your account. </strong>
                  Your Personal Data can enable access to multiple functions of our Service that are
                  available to registered users.
                </li>
                <li>
                  <strong>Marketing and promotional initiatives. </strong>
                  Blockskillo will use non-specific information gathered from you in order to
                  improve our marketing efforts.
                </li>
                <li>
                  <strong>Targeted advertising. </strong>
                  Your Personal Data will be used to improve advertising efforts that are relevant
                  to your specific interests.
                </li>
                <li>
                  <strong>Dispute resolution and site protection. </strong>
                  Your information will be used in the instance of a legal dispute to resolve issues
                  related to our Service.
                </li>
              </ul>
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="heading-text-2 mt-5">How We Share Your Information</h3>
            <p className="mt-3">
              Blockskillo will share your information, when applicable, in the following situations:
              <br /> <br />
              <strong>With your consent. </strong>Blockskillo will share your information for any
              purpose with your explicit consent.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="heading-text-2 mt-5">Third-party Sharing</h3>
            <p className="mt-3">
              Any third party we share your information with must disclose the purpose for which
              they intend to use your information. They must retain your information only for the
              duration disclosed when requesting or receiving said information. The third-party
              service provider must not further collect, sell, or use your personal information
              except as necessary to perform the specified purpose.
              <br /> <br />
              Your information may be shared to a third-party for reasons including:
              <br />
              <ul className="list-1 ml-5">
                <li>
                  <strong>Analytics information. </strong>
                  Your information might be shared with online analytics tools in order to track and
                  analyse website traffic.
                </li>
                <li>
                  <strong> Marketing initiatives. </strong>
                  Your information will be used for generating and sending newsletters, email
                  marketing efforts, advertisements, and more.
                </li>
                <li>
                  <strong>Improving targeted advertising campaigns. </strong>
                  Blockskillo partners with third party service providers to improve targeted
                  advertising campaigns.
                </li>
              </ul>
              <br />
              If you choose to provide such information during registration or otherwise, you are
              giving Blockskillo permission to use, share, and store that information in a manner
              consistent with this Privacy Policy.
              <br /> <br />
              Your information may be disclosed for additional reasons, including:
              <ul className="list-1 ml-5">
                <li>Complying with applicable laws, regulations, or court orders.</li>
                <li>
                  Responding to claims that your use of our Service violates third-party rights.
                </li>
                <li>Enforcing agreements you make with us, including this Privacy Policy.</li>
              </ul>
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="heading-text-2 mt-5">Cookies</h3>
            <p className="mt-3">
              Cookies are small text files that are placed on your computer by websites that you
              visit. Websites use cookies to help users navigate efficiently and perform certain
              functions. Cookies that are required for the website to operate properly are allowed
              to be set without your permission. All other cookies need to be approved before they
              can be set in the browser.
              <br /> <br />
              <ul className="list-1 ml-5">
                <li>
                  <strong>Strictly necessary cookies. </strong>
                  Strictly necessary cookies allow core website functionality such as user login and
                  account management. The website cannot be used properly without strictly necessary
                  cookies.
                </li>
                <li>
                  <strong> Performance cookies. </strong>
                  Performance cookies are used to see how visitors use the website, eg. analytics
                  cookies. Those cookies cannot be used to directly identify a certain visitor.
                </li>
                <li>
                  <strong>Targeting cookies. </strong>
                  Targeting cookies are used to identify visitors between different websites, eg.
                  content partners, banner networks. Those cookies may be used by companies to build
                  a profile of visitor interests or show relevant ads on other websites.
                </li>
                <li>
                  <strong>Functionality cookies. </strong>
                  Functionality cookies are used to remember visitor information on the website, eg.
                  language, timezone, enhanced content.
                </li>
              </ul>
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="heading-text-2 mt-5">Security</h3>
            <p className="mt-3">
              Your information’s security is important to us. blockskillo.com utilizes a range of
              security measures to prevent the misuse, loss, or alteration of the information you
              have given us. However, because we cannot guarantee the security of the information
              you provide us, you must access our service at your own risk.
              <br /> <br />
              Blockskillo is not responsible for the performance of websites operated by third
              parties or your interactions with them. When you leave this website, we recommend you
              review the privacy practices of other websites you interact with and determine the
              adequacy of those practices.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="heading-text-2 mt-5">Contact Us</h3>
            <p className="mt-3 mb-5">
              For any questions, please contact us through the following methods: <br /> <br />
              Name: Blockskillo Education Technologies (OPC) Pvt. Ltd. <br /> <br />
              Address: 4F-10, Darshanam Arise, Gotri Road, Gotri, Vadodara 390021. Gujarat. India.
              <br /> <br />
              Email: <a href="mailto:learn@blockskillo.com">learn@blockskillo.com</a> <br /> <br />
              Website: <a href="https://www.blockskillo.com">https://www.blockskillo.com</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
