import React, { useEffect, useState } from 'react';
import { useNavigate, Navigate, useLocation } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import 'styles/form.css';

import { useFormik } from 'formik';
import lodash from 'lodash';
import store  from 'store'
import * as Yup from 'yup';
import { apiLoginWithEmail } from 'api/authentication';
import { apiLoginWithEmailVerify } from 'api/authentication';


const CountDown = React.memo(({seconds}) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return <>{minutes} Min. {remainingSeconds} Sec.</>;
});

CountDown.displayName = "CountDown"

const LoginPage = () => {

    const timeOut = 300; 
    const [countdown, setCountdown]   = useState(timeOut);
    const [isCounting, setIsCounting] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const sourcePage = lodash.get(location,'state.from');

    useEffect(() => {

      let timerId;
      if (isCounting) { timerId = setInterval(() => { setCountdown((prevCountdown) => prevCountdown - 1); }, 1000); }

      return () => { clearInterval(timerId); };
    }, [isCounting]);

    useEffect(() => { if(countdown === 0) { setIsCounting(false); setCountdown(timeOut) } },[countdown])

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email format').required('Email is required'),
        otp:   Yup.string().length(6,'OTP must be exactly 4 digits').required('OTP is required'),
    })

    const dataForm = useFormik({
        initialValues    : { email : '', otp: '' },
        validationSchema : validationSchema,
        onSubmit: async (values) => { 
            const { status, data, error } = await apiLoginWithEmailVerify(values);
            
            if(status === false) { setCountdown(300); setIsCounting(false); return toast.error(error); }

            store.set('token',data.tokens.access.token);
            store.set('user', data.user);

            return navigate(sourcePage ?? '/dashboard');
        },
    })


    const generateOTP = async () => {

        if(lodash.isEmpty(dataForm.values.email)) { return toast.error("Please enter your email."); }

        else { setIsCounting(true); }

        const { status, error} = await apiLoginWithEmail({ email : dataForm.values.email });

        if(status === false) { setCountdown(300); setIsCounting(false); return toast.error(error); }
    }

    if(!lodash.isEmpty(store.get('token'))) { return <React.Fragment><Navigate to="/dashboard" /></React.Fragment> }

    return (
    <section className="section section-login login-box">
        <div className="container">
            <div className="login-form-box">
            <div>
                <div className="section-login-title"> 
                    <h4>Log In</h4>
                </div>
                <ToastContainer autoClose={2000} />
      
                <div className="  mt-3 ">
                    <div>
                        <form className="mobile-row" onSubmit={(e) => { e.preventDefault(); dataForm.handleSubmit(); }} >
                        <div className="form-handle inputColumn ">
                            <div className="input-group">
                                <input 
                                    type="text" 
                                    className="form-control Input_wrapper primaryInput" 
                                    placeholder="Enter your Email" 
                                    aria-label="Recipient's username" 
                                    aria-describedby="basic-addon2" 
                                    value={dataForm.values.email}
                                    onChange={dataForm.handleChange}
                                    name='email'
                                />
                                <div className="input-group-append generateButton">
                                    <button className="input-group-text PrimaryBtn primaryInput sendotpbutton" 
                                          id="basic-addon2" 
                                          onClick={generateOTP} 
                                          disabled={isCounting}
                                    >
                                    { isCounting && <CountDown seconds={countdown}></CountDown> } 
                                    { isCounting === false && 'Send OTP' }
                                    </button>
                                </div>
                            </div>
                            <div className='error mx-3'>{dataForm.touched.email && dataForm.errors.email}</div>
                        </div>

                        <div className=" form-handle inputColumn otpColumn">
                            <label className="form-label formHead otpLabel">Enter OTP</label>
                            <OtpInput
                                className="otpInput"
                                value={dataForm.values.otp}
                                onChange={(value) => { dataForm.setFieldValue('otp',value); }}
                                numInputs={6}
                                renderSeparator={<span>-</span>}
                                renderInput={(props) => <input {...props} className='otp-input' />}
                                inputType='number'
                            />
                            <div className='error mx-3'>{ dataForm.touched.otp && dataForm.errors.otp}</div>
                        </div>
                        <div className=" form-handle text-center">
                            <button className="btn_wrapper PrimaryBtn verify-button p-2 " type='submit' width="100%" style={{ padding: "0.3rem 3rem" }}>
                                Verify
                            </button>
                        </div>
                </form>
                </div>
                </div>

                    {/* <div className="col-12 section-login-links">
                        <p>{`Don't have an account? `} <Link to="/register">Register</Link></p>
                        <Link to="/forgot-password">Forgot password</Link>
                    </div> */}
            </div>
            </div>
        </div>
    </section>
);};

export default LoginPage;
