export const navbarRegistry = [
  { id: 1, name: 'Courses', path: '/courses' },
  // { id: 2, name: 'Hiring', path: '/' },
  { id: 1, name: 'Skill-tokens', path: '/staas' },
  { id: 3, name: 'About Us', path: '/about-us' },
];

export const footerRegistry = [
  {
    id: 1,
    name: 'facebook',
    path: 'https://www.facebook.com/profile.php?id=100090828846341',
    icon: <ion-icon name="logo-facebook" class="footer-icon"></ion-icon>,
  },
  {
    id: 2,
    name: 'linkedin',
    path: 'https://www.linkedin.com/company/blockskillo/about/?viewAsMember=true',
    icon: <ion-icon name="logo-linkedin" class="footer-icon"></ion-icon>,
  },
  // {
  //   id: 3,
  //   name: 'youtube',
  //   path: '',
  //   icon: <ion-icon name="logo-youtube" class="footer-icon"></ion-icon>,
  // },
  // {
  //   id: 4,
  //   name: 'twitter',
  //   path: '',
  //   icon: <ion-icon name="logo-twitter" class="footer-icon"></ion-icon>,
  // },
  // {
  //   id: 5,
  //   name: 'github',
  //   path: '',
  //   icon: <ion-icon name="logo-github" class="footer-icon"></ion-icon>,
  // },
];

export const footerKnowMoreRegistry = [
  { id: 1, title: 'About Us', path: '/about-us' },
  { id: 2, title: 'Contact Us', path: '/contact-us' },
  { id: 3, title: 'How it Works', path: '/' },
];

export const footerCompanyRegistry = [
  { id: 1, title: 'Privacy Policy', path: '/privacy-policy' },
  { id: 2, title: 'Terms and Conditions', path: '/terms-and-conditions' },
  { id: 3, title: 'Help', path: '/' },
];
