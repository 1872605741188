import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import ScrollToTop from './ScrollToTop';

import Header from '../components/Header';
import Footer from '../components/Footer';
import WhatsApp from './WhatsApp';

const WrapperContainer = styled.div`
  min-height: calc(100vh - 80px);
`;

const WebLayout = () => {
  return (
    <>
      <WhatsApp />
      <Header />
      <WrapperContainer>
        <ScrollToTop />
        <Outlet />
      </WrapperContainer>
      <Footer />
    </>
  );
};

export default WebLayout;
