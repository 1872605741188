import React from "react";
import "styles/video.css";
import { Link } from "react-router-dom";
import Raj from "../assets/images/raj.png";
import Erik from "../assets/images/erika.jpg";
import Kumar from "../assets/images/kumar.png";
import Linked from "../assets/images/linkedin.png";

function Advisor() {
  return (
    <div className="addSection">
      <div className="container">
        <div className="job">
          <div className="headVideo">
            <span>Advisors</span>
          </div>
          <div className="addImg">
            <div className="row">
              <div className="col-md-4">
                {/* leftAdd */}
                <div className="advisorImg mobAdd ">
                  <img className="addimg" src={Raj} />
                  <div className="addContentleft">
                    <p>Raj Kapoor</p>
                    <div className="addLink">
                      <Link
                        target="_blank"
                        to="https://www.linkedin.com/in/indieblock/"
                      >
                        <img src={Linked} />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                {/* rightAdd */}
                <div className="advisorImg ">
                  <img className="addimg" src={Kumar} />
                  <div className="addContentright">
                    <p>Kumar Ekambaram</p>
                    <div className="addLink">
                      <Link
                        target="_blank"
                        to="https://www.linkedin.com/in/kumar-ekambaram-119977b?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BIrb2z9BiTM%2BFHnlb8mx8Cg%3D%3D"
                      >
                        <img src={Linked} />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                {/* rightAdd   */}
                <div className="advisorImg ">
                  <img className="addimg" src={Erik} />
                  <div className="addContentright">
                    <p>Alina Tudorache</p>
                    <div className="addLink">
                      <Link
                        target="_blank"
                        to="https://www.linkedin.com/in/alinatudorache/"
                      >
                        <img src={Linked} />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Advisor;
