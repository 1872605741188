import MountModule from 'modules/mount.module';
import { MountSwitch } from 'modules/mount.module';
import { BsExclamationTriangleFill as ErrorSVG, BsPlugin } from 'react-icons/bs';
import { BiWallet as WalletSVG } from 'react-icons/bi';
import { MdOutlineAttachMoney as MoneySVG } from 'react-icons/md';

import 'styles/metamask-wallet.css'

const MetamaskWallet = (props) => {
    const downloadExtension = () => {
        const userAgent = navigator.userAgent.toLowerCase();

        if (userAgent.includes('chrome')) {
            return 'https://chrome.google.com/webstore/detail/nkbihfbeogaeaoehlefnkodbefgpgknn';
        } else if (userAgent.includes('firefox')) {
            return 'https://addons.mozilla.org/firefox/addon/ether-metamask/';
        } else if (userAgent.includes('safari')) {
            return 'https://apps.apple.com/us/app/metamask/id1438144202';
        } else if (userAgent.includes('opera')) {
            return 'https://addons.opera.com/extensions/details/metamask/';
        } else if (userAgent.includes('edg')) {
            return 'https://microsoftedge.microsoft.com/addons/detail/oneclick-ethereum/blekmdmclnfbacemhdmaapfbbopinipd';
        } else {
            return 'https://example.com/metamask-download';
        }
    }

    return (
        <div className="meta-mast-component">
            <MountModule condition={props.walletState.haveMetamask}>
                <MountSwitch condition={props.walletState.isConnected}>
                    <MountSwitch.True>
                        <div className="wallet">
                            <h4 className='wallet-details'>Wallet Details</h4>
                            <div className="card-row"><div className='wallet-address'>
                                <WalletSVG className='wallet-address-svg' />
                                <span className='wallet-address-primary'>Wallet Address:</span>
                                <span className='wallet-address-secondary'>{props.walletData.accountAddress.slice(0, 10)}</span>
                            </div></div>
                            <div className="card-row"><div className="wallet-amount">
                                <MoneySVG className='wallet-amount-svg' />
                                <span className='wallet-amount-primary'>Wallet Balance:</span>
                                <span className='wallet-amount-secondary'>{props.walletData.accountBalance}</span>
                            </div></div>
                        </div>
                    </MountSwitch.True>
                    <MountSwitch.False>
                        <p className='wallet-not-connected'>
                            <ErrorSVG className='wallet-not-connected-svg' />
                            <span className='wallet-not-connected-msg'>Wallet Not Connected</span>
                        </p>
                        <p className="wallet-connect-button" onClick={props.connectWallet}>
                            <BsPlugin></BsPlugin>&nbsp;Connect
                        </p>
                    </MountSwitch.False>
                </MountSwitch>
            </MountModule>


            <MountModule condition={!props.walletState.haveMetamask}>
                <p className='extension-not-found'>
                    <ErrorSVG className='extension-not-found-error-svg' />
                    <span className='extension-not-found-error-msg'>Please Install MetaMask</span>
                </p>
                <a target='_blank' rel="noreferrer" className='extension-not-found-error-link' href={downloadExtension()} >Download MetaMask</a>
            </MountModule>
        </div>
    );
}

export default MetamaskWallet;
