import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AiFillEyeInvisible,AiFillEye } from 'react-icons/ai';
import { apiSignUpWithEmail } from "api/authentication";
import { ToastContainer, toast } from 'react-toastify';

const SignUp = () => {
  
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = useCallback(() => {
    setShowPassword(!showPassword);
  });

  const formik = useFormik({
      initialValues: {
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          password: "",
      },
      validationSchema:Yup.object({
          firstName: Yup.string().required("First Name is required"),
          lastName: Yup.string().required("Last Name is required"),
          email: Yup.string().required("Email is required").email("Invalid email format"),
          phoneNumber: Yup.string().required("Phone Number is required"),
          password: Yup.string()
              .required("Password is required")
              .matches(/^(?=.*[A-Za-z])(?=.*\d)/,'Password must contain at least one letter and one number')
              .min(8, "Password must be at least 8 characters"),
          dob: Yup.date().required("Date of Birth is required"),
      }),
      onSubmit: async (payload) => {
        const { status, error } = await apiSignUpWithEmail(payload);
            
        if(status === false) { return toast.error(error); }

        else { toast.success('Signed Up successfully!'); }
          
        setTimeout(() => { navigate("/"); }, 3500);
      },
  });

  return (
    <section className="section section-login login-box">
      <div className="container"><div className="login-form-box Signup"><div>
            <div className="section-login-title">
              <h4>Sign Up</h4><ToastContainer autoClose={2000} />
            </div>
            <div className="mt-3">
              <form onSubmit={formik.handleSubmit}>
                <div className="form-handle inputColumn">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control Input_wrapper primaryInput"
                      placeholder="First Name"
                      name="firstName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.firstName}
                    />
                  </div>
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <div className="error">{formik.errors.firstName}</div>
                  ) : null}
                </div>
                <div className="form-handle inputColumn">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control Input_wrapper primaryInput"
                      placeholder="Last Name"
                      name="lastName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.lastName}
                    />
                  </div>
                  {formik.touched.lastName && formik.errors.lastName ? (
                    <div className="error">{formik.errors.lastName}</div>
                  ) : null}
                </div>
                <div className="form-handle inputColumn">
                  <div className="input-group">
                    <input
                      type="email"
                      className="form-control Input_wrapper primaryInput"
                      placeholder="Enter Your Email"
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                  </div>
                  {formik.touched.email && formik.errors.email ? (
                    <div className="error">{formik.errors.email}</div>
                  ) : null}
                </div>
                <div className="form-handle inputColumn">
                  <div className="input-group">
                    <input
                      type="tel"
                      className="form-control Input_wrapper primaryInput"
                      placeholder="Enter Your Phone Number"
                      name="phoneNumber"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phoneNumber}
                    />
                  </div>
                  {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                    <div className="error">{formik.errors.phoneNumber}</div>
                  ) : null}
                </div>

                <div className="form-handle inputColumn">
                    <div className="input-group">
                      <input
                        type="date"
                        className="form-control Input_wrapper primaryInput"
                        placeholder="Date of Birth"
                        name="dob"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.dob}
                      />
                    </div>
                    { formik.errors.dob ? (<div className="error">{formik.errors.dob}</div>) : null }
                </div>

                <div className="form-handle inputColumn">
                  <div className="input-group">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control Input_wrapper primaryInput"
                      placeholder="Enter Your Password"
                      name="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                    />
                    <div className="password-toggle" onClick={togglePasswordVisibility}>
                        {showPassword ? 
                        (<AiFillEyeInvisible className="toggle-icon" style={{ fontSize:'auto' }}></AiFillEyeInvisible>) : 
                        (<AiFillEye className="toggle-icon" style={{ fontSize:'auto' }}></AiFillEye>)}
                    </div>
                  </div>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="error">{formik.errors.password}</div>
                  ) : null}
                </div>
                <div className="form-handle text-center">
                  <button
                    className="btn_wrapper PrimaryBtn verify-button p-2"
                    type="submit"
                    style={{ padding: "0.3rem 3rem" }}
                  >
                    Verify
                  </button>
                </div>
              </form>
            </div>
      </div></div></div>
    </section>
); };

export default SignUp;
