import React from 'react';

export default function Card({
  children,
  padding,
  borderRadius,
  height,
  width,
  className,
  margin,
}) {
  const createClass = 'card_wrapper ' + className;

  return (
    <div
      className={createClass}
      style={{
        padding: padding || 'auto',
        borderRadius: borderRadius || 'auto',
        margin: margin || 'auto',
        width: width || '100%',
        height: height || 'auto',
      }}
    >
      <span className="border-line"></span>
      {children}
    </div>
  );
}
