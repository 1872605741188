import React, { useEffect, useState, useRef, useCallback } from "react";
import store from "store";
import { ethers } from "ethers";
import lodash from "lodash";
import moment from "moment";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import logOutButton from "../assets/images/logout.png";

import logo from "assets/images/logo.png";
import walletImage from "assets/images/walletImage.svg";
import profile from "assets/images/profile-pic.jpeg";

import { apiUserDetails } from "api/users";
import { apiCourses, apiAdminCourses, apiEnrolledStudents } from "api/courses";

import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import "styles/dashboard.css";
import {
  Navbar,
  Nav,
  NavDropdown,
  Overlay,
  Popover,
  Modal,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import CourseCard from "components/dashboard/course-card";
import DataTable from "components/dashboard/data-table";
import EnquiryReport from "components/dashboard/enquiry-report";
import MountModule from "modules/mount.module";
import Certificate from "components/dashboard/certificate";
import AdminCourseCard from "components/dashboard/admin/admin-course-card";
import ComponentKYC from "components/dashboard/admin/kyc-component";
import MetamaskWallet from "components/dashboard/admin/metamask-wallet";
import SearchStudentComponent from "components/dashboard/admin/search-students";

const Dashboard = () => {
  const [selectedOption, setSelectedOption] = useState("Wallet");
  const [wallet, toggleWallet] = useState(false);
  const [overlayRootClose, toggleOverlayRootClose] = useState(true);
  const targetRef = useRef(null);

  const handleOverlayClose = () => {
    toggleWallet(false);
    setSelectedOption("Wallet");
  };
  const handleSelect = (eventKey) => {
    setSelectedOption(eventKey);
    toggleWallet(!wallet);
  };

  const [userDetails, updateUserDetails] = useState(
    store.get("user") ?? { role: "user" }
  );
  const [courses, updateCourses] = useState({ results: [], page: 1 });
  const [modal, toggleModal] = useState(false);
  const [enquiryModal, toggleEnquiryModal] = useState(false);
  const [courseDetails, updateCourseDetails] = useState(null);
  const navigate = useNavigate();

  const handleModal = () => toggleModal(!modal);

  const logout = () => {
    store.clearAll();
    navigate("/login");
  };
  const enquiry = () => {
    toggleEnquiryModal(!enquiryModal);
  };
  const closeEnquiryModal = useCallback(() => {
    toggleEnquiryModal(false);
  }, [enquiryModal]);

  const handleCertificate = useCallback(
    (data) => {
      updateCourseDetails(data);
      toggleModal(!modal);
    },
    [modal]
  );

  const getUserDetails = async () => {
    const { status, error, data } = await apiUserDetails(userDetails.id);

    if (status === false) {
      return toast.error(error.message);
    }

    store.set("user", data);
    updateUserDetails(data);
  };

  const getCourses = async () => {
    const { status, error, data } = await apiCourses({
      userId: userDetails.id,
    });
    if (status === false) {
      return toast.error(error.message);
    }
    return updateCourses({ ...courses, ...data });
  };

  const getAdminCourses = async (params) => {
    const { status, error, data } = await apiAdminCourses(params);
    if (status === false) {
      return toast.error(error.message);
    }
    return updateCourses({ ...courses, ...data });
  };

  const [loading, toggleLoading] = useState(false);
  const [enrolledStudents, updateEnrolledStudents] = useState([]);

  const getEnrolledStudents = async (props = {}) => {
    const { status, data } = await apiEnrolledStudents(props.id);
    if (status === true) {
      updateEnrolledStudents([...data]);
    }
    toggleLoading(false);
  };

  useEffect(() => {
    getUserDetails();
  }, [true]);
  useEffect(() => {
    if (userDetails.role === "user") {
      getCourses();
    }
    if (userDetails.role === "admin") {
      getAdminCourses({});
    }
  }, [userDetails.role]);

  const handleKYC = useCallback((kycStatus) => {
    updateUserDetails({ ...userDetails, kyc: kycStatus });
  }, []);

  const enrolledStudentsModal = useCallback(
    (props) => {
      updateCourseDetails(props);
      toggleModal(true);
      toggleLoading(true);
      getEnrolledStudents(props);
    },
    [modal]
  );

  const exportEnrolledStudents = useCallback(async (props) => {
    const toastID = toast.loading("Please wait...");
    const { status, data, error } = await apiEnrolledStudents(props.id);

    try {
      if (status === false) {
        throw new Error(error);
      }

      const sorted = lodash.sortBy(data, "enquiryFullName");
      const array = lodash.map(sorted, (data) => {
        const object = {};

        object.certificateCode = data.certificateCode;
        object.firstName = data.firstName;
        object.lastName = data.lastName;
        object.completedSkill = data.completedSkill;
        object.totalSkill = data.totalSkill;
        object.enquiryDate = moment(data.certificateDate).format(
          "DD-MM-YYYY hh:mm A"
        );
        object.cellPhone = data.cellPhone;
        object.email = data.email;

        return object;
      });

      const worksheet = XLSX.utils.json_to_sheet(array);
      const headingColumns = [
        "NFT Certificate Code",
        "First Name",
        "Second Name",
        "Skill Token Score",
        "Out of Total",
        "Start Date",
        "Contact Number",
        "Email",
      ];
      const workbook = XLSX.utils.book_new();

      XLSX.utils.sheet_add_aoa(worksheet, [headingColumns], { origin: "A1" });
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `${props.title}.xlsx`);

      toast.update(toastID, {
        render: "File Exported",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error) {
      toast.update(toastID, {
        render: error.message,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  }, []);

  const searchAdminCourses = useCallback((props) => {
    return getAdminCourses(props);
  }, []);

  // metamask-code -------------------------------------------------------------------------------------------

  const [walletState, updateWalletState] = useState({
    haveMetamask: window.ethereum ? true : false,
    isConnected: false,
  });
  const [walletData, updateWalletData] = useState({
    accountAddress: "",
    accountBalance: "",
  });

  const connectWallet = useCallback(async () => {
    try {
      if (!userDetails.kyc) {
        return toggleWallet(true);
      }

      if (!window.ethereum) {
        return updateWalletState({ haveMetamask: false, isConnected: false });
      }

      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const balance = await provider.getBalance(accounts[0]);
      const actualBalance = ethers.utils.formatEther(balance);

      updateWalletState({ haveMetamask: true, isConnected: true });
      updateWalletData({
        accountAddress: accounts[0],
        accountBalance: actualBalance,
      });
    } catch (error) {
      return updateWalletState({ haveMetamask: false, isConnected: false });
    }
  });

  // ---------------------------------------------------------------------------------------------------------

  return (
    <React.Fragment>
      <div className="container mainBackground">
        <Navbar
          bg="dark"
          expand="lg"
          className="navbar-background navbar-fixing"
        >
          <Link to="/" className="navbar-brand">
            <img src={logo} alt="Logo" className="website-logo" />
          </Link>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="hamburger-for-mob"
          ></Navbar.Toggle>
          <Navbar.Collapse
            id="basic-navbar-nav bg-light"
            className="navbar-dropdown-row"
          >
            <Nav
              className="ml-auto bg-success wallet-handler"
              onSelect={handleSelect}
              ref={targetRef}
            >
              <NavDropdown title={selectedOption} id="basic-nav-dropdown">
                <NavDropdown.Item eventKey="MetaMask">
                  MetaMask
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="Blockskillo">
                  Blockskillo
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <img src={walletImage} alt="Wallet" className="walletPic" />
            <div className="dropdown">
              <button
                className="btn dropdown-toggle profile-dropdown"
                type="button"
                data-toggle="dropdown"
              >
                <img
                  src={profile}
                  alt="dropdown-image"
                  className="img-responsive dropdown-image"
                />
                <span className="caret"></span>
              </button>
              <ul className="dropdown-menu">
                <li
                  onClick={() => {
                    logout();
                  }}
                >
                  <a href="#">Log Out</a>
                </li>
              </ul>
            </div>
          </Navbar.Collapse>
          <div className="navbar-for-mobile">
            <Nav
              className="bg-success wallet-handler"
              onSelect={handleSelect}
              ref={targetRef}
            >
              <NavDropdown title={selectedOption} id="basic-nav-dropdown">
                <NavDropdown.Item eventKey="Wallet">Wallet</NavDropdown.Item>
                <NavDropdown.Item eventKey="Metamask">
                  Metamask
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="Blockskillo">
                  Blockskillo
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <div
              className="logout-for-mobile"
              onClick={() => {
                logout();
              }}
            >
              <img src={logOutButton} alt="" />
            </div>
          </div>
        </Navbar>

        <Overlay
          className="dropdown-tab-section-card"
          rootClose={overlayRootClose}
          show={wallet}
          target={targetRef.current}
          placement="bottom"
          container={document.body}
          onHide={handleOverlayClose}
        >
          <Popover
            id="popover"
            style={{ zIndex: "9999", margin: "0", padding: "0" }}
          >
            <Popover.Body>
              <MountModule condition={!userDetails.kyc}>
                <ComponentKYC
                  handleKYC={handleKYC}
                  userId={userDetails.id}
                  toggleOverlayRootClose={toggleOverlayRootClose}
                  toggleWallet={toggleWallet}
                />
              </MountModule>
              <MountModule condition={true}></MountModule>
            </Popover.Body>
          </Popover>
        </Overlay>

        <div className="row">
          <div className="col-lg-12">
            <div className="dashboardHeading">
              <h2>
                Welcome, {`${userDetails.firstName} ${userDetails.lastName} `}!
              </h2>
              <p>Dashboard</p>
            </div>

            <div className="courseSection">
              <MetamaskWallet
                connectWallet={connectWallet}
                walletState={walletState}
                walletData={walletData}
              ></MetamaskWallet>
              <MountModule condition={userDetails.role === "user"}>
                <a href="#" className="yourCourse">
                  Your Courses
                </a>
              </MountModule>
              <MountModule condition={userDetails.role === "admin"}>
                <div className="d-flex justify-content-between">
                  <div>
                    <a href="#" className="yourCourse">
                      Your Courses
                    </a>
                    <a
                      href="#"
                      className="yourCourse mx-4"
                      onClick={() => {
                        enquiry();
                      }}
                    >
                      Enquiry Details
                    </a>
                  </div>
                  <SearchStudentComponent
                    style={{ background: "#fff !important" }}
                    searchCourse={searchAdminCourses}
                  ></SearchStudentComponent>
                </div>

                <div className="dashboardCards">
                  <div className="row">
                    {lodash.map(courses.results, (data, index) => (
                      <React.Fragment key={index}>
                        <AdminCourseCard
                          handleDownload={exportEnrolledStudents}
                          handleCertificate={enrolledStudentsModal}
                          data={data}
                        />
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </MountModule>

              <MountModule condition={userDetails.role === "user"}>
                <div className="dashboardCards">
                  <div className="row">
                    {lodash.map(courses.results, (data, index) => (
                      <React.Fragment key={index}>
                        <CourseCard
                          handleCertificate={handleCertificate}
                          data={data}
                        />
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </MountModule>
            </div>
          </div>
        </div>

        <Modal
          show={modal}
          size="xl"
          onHide={handleModal}
          backdrop="static"
          keyboard={false}
          className="certified-modal"
        >
          <Modal.Header className="certificate-header">
            <Button onClick={handleModal}>X</Button>
          </Modal.Header>
          <MountModule condition={userDetails.role === "user"}>
            <Modal.Body className="modal-body certified-body">
              {/* <img src={certificateSource} alt='certificate' onLoad={() => { console.log('props'); }}></img> */}
              <Certificate
                userDetails={userDetails}
                courseDetails={courseDetails}
              ></Certificate>
            </Modal.Body>
          </MountModule>
          <MountModule condition={userDetails.role === "admin"}>
            <Modal.Body className="modal-body">
              <div className="datatable-column">
                <DataTable
                  courseDetails={courseDetails}
                  data={enrolledStudents}
                  loading={loading}
                />
              </div>
            </Modal.Body>
          </MountModule>
        </Modal>

        <ToastContainer></ToastContainer>

        <Modal
          show={enquiryModal}
          size="xl"
          onHide={() => {
            toggleEnquiryModal(false);
          }}
          backdrop="static"
          keyboard={false}
          className="certified-modal"
        >
          <Modal.Header className="certificate-header">
            {/* <Button onClick={() => { toggleEnquiryModal(false) }}>X</Button> */}
          </Modal.Header>
          <Modal.Body className="modal-body">
            <EnquiryReport
              closeEnquiryModal={closeEnquiryModal}
            ></EnquiryReport>
          </Modal.Body>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
