import React from 'react';

function NoMatch() {
  return (
    <div style={{ padding: '100px', textAlign: 'center', background: '#fff', height: '100vh' }}>
      <h2>404: Page Not Found</h2>
      <p>
        <a href="/">Go home</a>
      </p>
    </div>
  );
}

export default NoMatch;
