import React from 'react';

import 'styles/main-page.css';
import HeroSection from '../components/HeroSection';
import VideoSection from 'components/videoSection';
import Benefit from 'components/benefit';
import Learning from 'components/learning';
import Job from 'components/job';
import Community from 'components/community';

export default function Registration() {
  return (
    <>
      <div className="mainBg">
      <HeroSection />
      <VideoSection/>
      <Benefit/>
      <Learning/>
      <Job/>
      <Community/>
      {/* <SkillsSection />
      <WhyUsSection />
      <HiringPartner /> */}
      </div>
    </>
  );
}
