import instance, { errorMessage } from "api";

const handleResponse = (response) => {
    return { status: true, data: response.data, message: response.data?.message || "" };
};

const handleError = (error) => {
    return { status: false, error: errorMessage(error), data: null };
};

export const apiUserDetails = async (userId) => {
    try {
        const response = await instance.get(`user/${userId}`);
        return handleResponse(response);
    } catch (error) { return handleError(error); }
};

export const apiUsers = async (searchParams) => {
    try {
        const response = await instance.get(`user`, { params: searchParams });
        return handleResponse(response);
    } catch (error) { return handleError(error); }
};

export const apiCompleteKYC = async (userId, payload) => {
    try {
        const response = await instance.post(`user/${userId}/kyc`, payload);
        return handleResponse(response);
    } catch (error) { return handleError(error); }
};

export const apiValidateCertificate = async (payload) => {
    try {
        const response = await instance.post(`certificate`, payload);
        return handleResponse(response);
    } catch (error) { return handleError(error); }
};

export const apiStoreClickData = async (userId, url) => {
    try {
        const response = await instance.post('course-click', { userId, url });
        return handleResponse(response);
    } catch (error) { return handleError(error); }
};
