import React from "react";
import "styles/video.css";
import Bag from "assets/images/icons/bag.svg";
import Yt from "assets/images/icons/YT.svg";
import Book from "assets/images/icons/book.svg";
import Message from "assets/images/icons/message.svg";
import Chat from "assets/images/icons/chat.svg";
import Tv from "assets/images/icons/tv.svg";
import Trade from "assets/images/icons/trad.svg";
import Bulb from "assets/images/icons/bulb.svg";
// import { Link } from "react-router-dom";

function Benefit() {
  return (
    <div className="BenefitSection">
      <div className="container">
        <div className="headVideo">
          <span>BENEFITS</span>
        </div>
        <div className="benefitCard">
          <div className="row">
            <div className="col-md-3 col-sm-6 mobrespo">
              <div className="topMargins">
                <div className="benefitCards">
                  <img src={Bag} />
                  <p>
                    100% Job <br /> assistance
                  </p>
                </div>
                <div className="benefitCards">
                  <img src={Yt} />
                  <p>
                    Live sessions by,
                    <br /> Blockchain <br />
                    Developers
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mobrespo">
              <div className="topMargin">
                <div className="benefitCards">
                  <img src={Book} />
                  <p>
                    Unique NFT <br /> Certificate,
                    <br /> cannot be <br /> duplicated
                  </p>
                </div>
                <div className="benefitCards">
                  <img src={Message} />
                  <p>
                    Mentoring on <br /> Projects by <br /> Experts
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mobrespo">
              <div className="topMargins">
                <div className="benefitCards">
                  <img src={Chat} />
                  <p>
                    *Free <br /> Interview <br /> prep course
                  </p>
                </div>
                <div className="benefitCards">
                  <img src={Tv} />
                  <p>
                    Course in <br /> English as well <br /> as in Hindi
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 mobrespo">
              <div className="topMargin">
                <div className="benefitCards">
                  <img src={Trade} />
                  <p>
                    *Free Startup <br /> funding <br /> course
                  </p>
                </div>
                <div className="benefitCards">
                  <img src={Bulb} />
                  <p>
                    Razor-focused <br /> skill based <br /> content
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="note">
          <a href="https://youtu.be/M5St1SwZ7m8"><p>Free courses, only for the first 50 students</p></a>
        </div>
      </div>
    </div>
  );
}

export default Benefit;
