import React from "react";
import "styles/thankyou-page.css";
import thanksImage from "assets/images/thanks-image-new.png";
import { AiFillHome } from "react-icons/ai";
import Logo from "assets/images/logo.png";
import { Link } from "react-router-dom";
// import thanksText from 'assets/images/Thankyou.png';
import { BsDownload } from "react-icons/bs";
import { endpoint } from 'api/index'

const Thankyou = () => {
  return (
    <div className="container">
      <div className="thnx-mainBackground">
        <div className="thanks-box">
          <div className="row">
            <div className="col-md-6">
              <div className="thnx-detail-section">
                <div className="thnx-logo-box">
                  <img src={Logo} alt="" />
                </div>
                {/* <div className="thnx-big-content">
                  <img src={thanksText} alt=".." />
                </div> */}
                <div className="mt-5 thnx-other-content">
                  <p>
                    Thank you for showing your interest for <br /> Blockskillo’s
                    Blockchain Developer Course.{" "}
                  </p>
                  <p>Our consoler will get in touch with you shortly.</p>
                </div>
                <div className="thnx-button-part">
                  <Link to="/" className="link-button">
                    <AiFillHome />
                    Home
                  </Link>
                  {/* <button>Download Brochure</button> */}
                </div>
                <div>
                  <a href={`${endpoint}/download-brochure`} className="dashDownload mt-4" type="submit">
                    <BsDownload />
                    Download Brochure
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="thanks-page-image">
                <img src={thanksImage} alt="thank you" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Thankyou;
