import { apiGetEnquiries } from 'api/enquiry';
import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import moment from 'moment';
import lodash from 'lodash'
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import 'styles/enquiry-report.css'

const EnquiryReport = (props) => {

    const [dataTable, updateDataTable] = useState([]);
    const [loading, toggleLoading] = useState(false);

    const columns = [{
        name: '#',
        sortable: true,
        width: '5%',
        selector: row => row.index,
    }, {
        name: 'Full Name',
        sortable: true,
        width: '20%',
        selector: row => row.enquiryFullName,
    }, {
        name: 'Email Id',
        sortable: true,
        width: '23%',
        selector: row => row.enquiryEmail,
    }, {
        name: 'Whatsapp No.',
        sortable: true,
        width: '16%',
        selector: row => row.enquiryWhatsappNumber,
    }, {
        name: 'Date',
        sortable: true,
        width: '20%',
        selector: data => moment(data.enquiryDate).format('YYYY-MM-DD'),
    }, {
        name: 'Time',
        sortable: true,
        width: '16%',
        selector: row => moment(row.enquiryDate).format('hh:mm:ss A'),
    }
    ];

    const getEnquiries = async () => {

        toggleLoading(true);

        const { status, error, data } = await apiGetEnquiries();

        if (status === false) { toggleLoading(false); return toast.error(error.message); }

        const enquiries = lodash.cloneDeep(data.results)
        toggleLoading(false); updateDataTable([...enquiries.map((data, index) => {
            data.date = moment(data.enquiryDate).format('YYYY-MM-DD');
            data.time = moment(data.enquiryDate).format('hh:mm:ss A');
            data.index = (index + 1); return data;
        })]);
    };

    useEffect(() => { getEnquiries(); }, []);

    const generateExcelData = (array) => {

        const data = lodash.map(array, (data) => {
            const object = {}

            object.enquiryFullName = data.enquiryFullName;
            object.enquiryEmail = data.enquiryEmail;
            object.enquiryWhatsappNumber = String(data.enquiryWhatsappNumber);
            object.date = moment(data.enquiryDate).format('YYYY-MM-DD');
            object.time = moment(data.enquiryDate).format('hh:mm:ss A');

            return object;
        })

        const worksheet = XLSX.utils.json_to_sheet(data);
        const headingColumns = ['Full Name', 'Email Id', 'Whatsapp No.', 'Date', 'Time'];
        const workbook = XLSX.utils.book_new();

        XLSX.utils.sheet_add_aoa(worksheet, [headingColumns], { origin: 'A1' });
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        return workbook;
    };

    const handleExport = (data) => {
        const workbook = generateExcelData(data);
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'Enquiry Report.xlsx');
    };

    return (
        <div>
            <div className="data-box-heading">
                <h4>Enquiries</h4>
                <div>
                    <button className='basic-nav-dropdown mx-3' onClick={() => handleExport(dataTable)}>Export</button>
                    <button onClick={() => props.closeEnquiryModal()}>Close </button>
                </div>
            </div>
            <div className="data-list-box">
                <DataTable columns={columns} data={dataTable} pagination progressPending={loading} />
            </div>
        </div>
    );
};

export default EnquiryReport;
