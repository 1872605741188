import React from "react";

const Mount = React.memo(({ condition, children }) => {
    if(condition === true){ return children }
    return (<React.Fragment></React.Fragment>)
});

Mount.displayName = 'Mount';

const MountSwitch = React.memo(({ condition, children }) => {
    const [isTrue, isFalse] = React.Children.toArray(children);
    return condition === true ? isTrue : isFalse;
});

MountSwitch.True  = ({ children }) => children;
MountSwitch.False = ({ children }) => children;

MountSwitch.displayName = 'MountSwitch'
MountSwitch.True.displayName  = 'MountSwitch.True'
MountSwitch.False.displayName = 'MountSwitch.False'

export { MountSwitch }

export default React.memo(Mount);
