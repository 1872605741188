import React from 'react'
import Jobscard from 'components/jobscard'

export default function Jobs() {
  return (
    <div>
      <Jobscard/>
    </div>
  )
}
