import React from 'react'

const CourseCard = (props) => {
    const data = props.data ?? { }

    return (
    <div className="col-lg-4 col-md-6 col-sm-12 mb-4"><div className="dashCardBoundary">
        <div className="dashCourseName">
            <span>{data.title}</span>
            <span>{data.level}</span>
        </div>
        <div className="dashCourseButtons">
            <button onClick={() => { props.handleCertificate(data) }} className="dashView">View</button>
            <button onClick={() => { props.handleDownload(data) }} className="dashDownload" style={{marginLeft:'1.5em'}}>Download</button>
        </div>
    </div></div>
); }

export default React.memo(CourseCard)
