import React from 'react';
// import { Link } from 'react-router-dom';

import 'styles/privacy-policy.css';

const ContactUs = () => {
  return (
    <section className="privacy-policy-section">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3 className="heading-text-2 mt-5">Contact Us</h3>
            <p className="mt-5 mb-5">
              For any questions, please contact us through the following methods: <br /> <br />
              Name: Blockskillo Education Technologies (OPC) Pvt. Ltd. <br /> <br />
              Address: 4F-10, Darshanam Arise, Gotri Road, Gotri, Vadodara 390021. Gujarat. India.
              <br /> <br />
              Email: <a href="mailto:learn@blockskillo.com">learn@blockskillo.com</a> <br /> <br />
              Website: <a href="https://www.blockskillo.com">https://www.blockskillo.com</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
