import React from 'react';

const HiringPartner = () => {
  return (
    <section className="section section-why-us pt-2">
      <div className="container">
        <div className="section-why-us-heading">
          <h3>Hiring Partner</h3>
        </div>
        <div className="row mt-4 text-center">
          <div className="col-12 col-md-4"></div>
          <div className="col-12 col-md-4">
            <a href="https://thebrandglobal.com/" target="_blank" rel="noreferrer">
              <img
                src="/images/partner/the-brand-global.png"
                alt="logo"
                loading="lazy"
                style={{ width: '230px', height: 'auto', borderRadius: '5px' }}
              />
            </a>
          </div>
          <div className="col-12 col-md-4"></div>
        </div>
      </div>
    </section>
  );
};

export default HiringPartner;
