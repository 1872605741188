import React from "react";
import { Link } from "react-router-dom";

function Community() {
  return (
    <div className="communitySection">
      <div className="container">
        <div className="headVideoss">
          <Link to="https://t.me/blockskillo">
            <span>Join the Community</span>
          </Link>
        </div>
      
      </div>
    </div>
  );
}

export default Community;
