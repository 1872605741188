import React from 'react';
import { Accordion, Button, Form as BootstrapForm  } from 'react-bootstrap';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { apiCompleteKYC } from 'api/users';
import { toast } from 'react-toastify';

const ComponentKYC = (props) => {
    const initialValues = { panNumber: '', };
    const validationSchema = Yup.object().shape({
        panNumber: Yup.string().required('PAN number is required').matches(/^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/, 'Invalid PAN number'),
    });

    const handleSubmit = async (values, { setSubmitting, resetForm, }) => {
        props.toggleOverlayRootClose(false);
        const toastIdentification = toast.loading("Please wait...")
        const response = await apiCompleteKYC(props.userId,values);
        
        if(response.status === false) {
            toast.update(toastIdentification, { render: response.error,   type: 'error', isLoading: false, autoClose: 1000 });
        } else {
            props.handleKYC(true);
            toast.update(toastIdentification, { render: response.message, type: 'success', isLoading: false, autoClose: 1000 });
        }

        setSubmitting(false); resetForm(); 
        setTimeout(() => { 
            props.toggleWallet(false); 
            props.toggleOverlayRootClose(true);
        }, 1800);
    };

  return (
    <div className="metamask-accordion">
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>KYC to be done for Metamask integration</Accordion.Header>
          <Accordion.Body>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
              {({ isSubmitting, values, handleChange, errors, touched }) => (
                <Form className="metamask-form">
                    <BootstrapForm.Group controlId="formBasicEmail">
                        <BootstrapForm.Label>PAN Number</BootstrapForm.Label>
                        <BootstrapForm.Control isInvalid={touched.panNumber && errors.panNumber} value={values.panNumber} name="panNumber" placeholder="Enter your PAN number" onChange={handleChange}/>
                        <ErrorMessage name="panNumber" component="div" className="error" />
                    </BootstrapForm.Group>
                    <div className="submit-metamask">
                        <Button className="dashDownload" type="submit" disabled={isSubmitting}>Submit</Button>
                    </div>
                </Form>
              )}
            </Formik>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
);};

export default React.memo(ComponentKYC);
