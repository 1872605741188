import React from 'react';
// import { Link } from 'react-router-dom';
import 'styles/privacy-policy.css';
import pdfUrl from 'assets/pdf/terms-and-conditions.pdf'; // Adjust path according to your project structure


const TermsAndConditions = () => {

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = 'terms-and-conditions.pdf';
    link.click();
  };

  return (
    <section className="terms-and-conditions-section">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="heading-text-1 text-center mt-2">Terms And Conditions</h2>
            <p className="subheading-text-1 mt-5">
              Welcome to Blockskillo Education Technologies OPC Pvt. Ltd. (to be referred for the
              purposes of this document as Blockskillo)!
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <p className="mt-5">
              These terms of use is an electronic record in the form of an electronic contract
              formed under the Information Technology Act, 2000 and the rules made thereunder and
              the amended provisions pertaining to electronic documents / records in various
              statutes as amended by the Information Technology Act, 2000. These terms of use does
              not require any physical, electronic or digital signature.
              <br /> <br />
              This document is published and shall be construed in accordance with the provisions of
              rule 3 (1) of the Information Technology (Intermediaries Guidelines) Rules, 2011
              prescribed under Information Technology Act, 2000 that require publishing the rules
              and regulations, privacy policy and user agreement for access or usage of the website.
              <br /> <br />
              These terms of use is a legally binding document between Blockskillo website and user
              (both terms defined below). These terms of use will be effective upon your acceptance
              of the same (directly or indirectly in electronic form or by means of an electronic
              record) and will govern the relationship between the Blockskillo website and user for
              the use of the website (defined below).
              <br /> <br />
              Please read these terms of use carefully. By using the website, you indicate that you
              understand, agree and consent to these terms of use. If you do not agree with the
              terms of these terms of use, please do not use this website. You hereby provide your
              unconditional consent or agreements to Blockskillo website as provided under section
              43a and section 72a of Information Technology Act, 2000 and the Information Technology
              (Intermediary Guidelines) Rules, 2011.
              <br />
              <br />
              These Terms of Use of the website located at the URL{' '}
              <a href="https://www.blockskillo.com">blockskillo.com</a>, mobile sites or mobile
              Website (&quot;Website&quot;) is between Blockskillo Education Technologies OPC
              Private Limited (&quot;Blockskillo Website&quot; or &quot;We&quot; or &quot;Us&quot;
              or &quot;Our&quot;), a company incorporated under the Companies Act, 2013 with its
              registered office situated at Sona Towers, 3rd Floor, No.2, 26, 27 and 3 Krishna Nagar
              Industrial Area, Hosur Rd, Bengaluru, Karnataka 560029. and the guest users or
              registered users of the Website (&quot;You&quot; or &quot;Your&quot; or
              &quot;Yourself&quot; or &quot;User&quot;) describe the terms on which the Blockskillo
              Website offers You access to the Website and such other services as are incidental and
              ancillary thereto (&quot;;Services&quot;).
              <br />
              <br />
              These Terms of Use is a contract between You and Blockskillo Website. These Terms of
              Use shall be read together with the Privacy Policy or other terms and condition with
              all other notices, disclaimers, guidelines appearing on the Website from time to time
              (collectively referred to as &quot;Agreement(s)&quot;) constitute the entire agreement
              upon which You are allowed to access and use the Website and avail the Services.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="heading-text-2 mt-5">1) Interpretation</h3>
            <p className="mt-3">
              a) Any reference to the singular includes a reference to the plural and vice versa,
              unless explicitly provided for otherwise; and any reference to the masculine includes
              a reference to the feminine and vice versa. <br /> <br />
              b) Headings and captions are used for convenience only and will not affect the
              interpretation of these Terms of Use. <br /> <br />
              c) Any reference to a natural person will, unless repugnant to the context, include
              his heirs, executors and permitted assignees. Similarly, any reference to a juristic
              person such as Blockskillo Website will, unless repugnant to the context, include its
              affiliates, successors and permitted assignees.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="heading-text-2 mt-5">2) Account registration and security</h3>
            <p className="mt-3">
              a) You may access and use the Website and the Services either as a registered user or
              as a guest user. However, not all sections of the Website and Services will be
              accessible to guest users. <br /> <br />
              b) Registered users: Blockskillo Website makes certain sections of the Services
              available to You through the Website only if You have provided Blockskillo Website
              certain required User information and created an account and a Blockskillo Website ID
              through certain log-in ID and password (&quot;Account&quot;). In the event You
              register as a User by creating an Account in order to avail of the Services provided
              by the Website, You will be responsible for maintaining the confidentiality and
              security of the Account, and are fully responsible for all activities that occur under
              Your Account. You agree to (a) immediately notify Blockskillo Website of any
              unauthorized use of Your Account Information or any other breach of security, and (b)
              ensure that You exit from Your Account at the end of each session. Blockskillo Website
              cannot and will not be liable for any loss or damage arising from Your failure to
              comply with this section. You may be held liable for losses incurred by Blockskillo
              Website or any other user of or visitor to the Website due to authorized or
              unauthorized use of Your Account as a result of Your failure in keeping Your Account
              Information secure and confidential. You shall ensure that the Account information
              provided by You in the Website&apos;s registration form is complete, accurate and
              up-to-date. Use of another user&apos;s Account information for availing the Services
              is expressly prohibited. If You provide any information that is untrue, inaccurate,
              not current or incomplete (or becomes untrue, inaccurate, not current or incomplete),
              or Blockskillo Website has reasonable grounds to suspect that such information is
              untrue, inaccurate, not current or incomplete, Blockskillo Website has the right to
              suspend or terminate Your Account and refuse any and all current or future use of the
              Website / Services (or any portion thereof).
              <br /> <br />
              c) Guest users: The Website also allows limited access to the Services for
              unregistered Users (also called as &apos;guest users&apos;). Such users will be able
              to browse the Website.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="heading-text-2 mt-5">3) Services</h3>
            <p className="mt-3">
              a) Blockskillo Website is an online platform inter-alia engaged in the business of
              rendering of services to a large community of users, where questions are asked,
              answered, edited, and organized by the users by means of a video/audio technology and
              other similar services through its Website. <br /> <br />
              b) Neither Blockskillo Website own, sell, resell, furnish, provide, prepare any
              product nor manage and/or control the Users. <br /> <br />
              c) Blockskillo Website responsibility is limited to facilitating the online services.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="heading-text-2 mt-5">4) Use of Website/ Services</h3>
            <p className="mt-3">
              You agree and undertake that when using a Website / Service, You will not: <br />{' '}
              <br />
              a) Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights of
              others; <br /> <br />
              b) Publish, post, upload, distribute or disseminate any inappropriate, profane,
              defamatory, infringing, obscene, indecent or unlawful topic, name, material or
              information;
              <br /> <br />
              c) Conduct or forward surveys, contests, pyramid schemes or chain letters; <br />{' '}
              <br />
              d) Falsify or delete any author attributions, legal or other proper notices or
              proprietary designations or labels of the origin or source of software or other
              material contained in a file that is uploaded; <br /> <br />
              e) Any information provided by You on this site shall not be misleading in any way;
              <br /> <br />
              f) use any deep-link, robot, spider or other automatic device, program, algorithm or
              methodology, or any similar or equivalent manual process, to access, acquire, copy or
              monitor any portion of the Website or content, or in any way reproduce or circumvent
              the navigational structure or presentation of the Website, to obtain or attempt to
              obtain any materials, documents or information through any means not specifically made
              available through the Website; <br /> <br />
              g) reverse engineer, modify, copy, distribute, transmit, display, perform, reproduce,
              publish, license, create derivative works from, transfer, or sell any information or
              software obtained from the Website.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="heading-text-2 mt-5">5) Course Cancellation and Refund Policy</h3>
            <p className="mt-3">
              BY ACCESSING OR AVAILING OUR SERVICES, YOU AGREE TO BE BOUND BY THE TERMS CONTAINED IN
              THIS COURSE CANCELLATION POLICY. IF YOU DO NOT AGREE TO THE TERMS IN THIS COURSE
              CANCELLATION POLICY, YOU ARE ADVISED NOT TO ACCESS OR AVAIL OUR SERVICES.
              <br /> <br />
              Course cancellation shall be as per the Course cancellation Policy described below.
              <br /> <br />
              5.1: The course cancellation, refund request has to be raised on the email{' '}
              <a href="mailto:learn@blockskillo.com">learn@blockskillo.com</a> only. No other forms
              of communication will be accepted for the said.
              <br /> <br />
              5.2: The Students can claim full-refund of the fees within the first one calendar week
              (1), seven (7) calendar days from the date of paying the fees in full. Paying implies
              the Fees getting credited in Blockskillo’s bank account. <br /> <br />
              5.3: Cancellation and refund DOES NOT apply for those who would have not paid the fees
              in full. <br /> <br />
              5.4: The course cannot be cancelled beyond 7 days from the date of payment unless
              there is an event of death or any such severe medical ground. <br /> <br />
              5.5: We reserve the right to modify this Course Cancellation Policy in whole or in
              part, at any time at Our discretion, at any time. The decision to amend may be due to
              changes in law or any other reason.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="heading-text-2 mt-5">
              6) Deferral of a batch is at the sole discretion of Blockskillo
            </h3>
            <p className="mt-3">
              The deferral will have to be raised at{' '}
              <a href="mailto:learn@blockskillo.com">learn@blockskillo.com</a>
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="heading-text-2 mt-5">7) Third Party Contents</h3>
            <p className="mt-3">
              a) We cannot and will not assure that other users are or will be complying with the
              foregoing rules or any other provisions of these Terms of Use, and, as between You and
              Us, You hereby assume all risk of harm or injury resulting from any such lack of
              compliance. <br /> <br />
              b) You acknowledge that when You access a link that leaves the Services, the site You
              will enter into is not controlled by Us and different terms of use and privacy policy
              may apply. By assessing links to other sites, You acknowledge that We are not
              responsible for those sites. We reserve the right to disable links to and / or from
              third-party sites to the Services, although we are under no obligation to do so.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="heading-text-2 mt-5">8) Promotions, discounts and coupons</h3>
            <p className="mt-3">
              Blockskillo Website reserves the right to offer the discounts/promotional offers to
              any Users of its own choice and shall not be held liable to any User for not offering
              the same. The discounts/offers have been made available at the sole discretion of
              Blockskillo Website and are subject to change / amendment / modification from time to
              time. Blockskillo Website at its sole discretion may at any time discontinue the
              discounts/offers without assigning any reasons or without any prior intimation
              whatsoever. The participation in discounts/offers is entirely voluntary and it is
              understood, that the participation by the User shall be deemed to have been made on a
              voluntary basis.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="heading-text-2 mt-5">9) Communications</h3>
            <p className="mt-3">
              When You use the Website or send emails or other data, information or communication to
              Us, You agree and understand that You are communicating with Us through electronic
              records and You consent to receive communications via electronic records from Us
              periodically and as and when required. We may communicate with You by email or by such
              other mode of communication, electronic or otherwise.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="heading-text-2 mt-5">10) Right To Use Logos</h3>
            <p className="mt-3">
              Nothing contained in these Terms of Use constitutes a license in favor of the User to
              use trademarks, service marks or logos and/or any other marks, owned by Blockskillo
              Website that may be reflected on the Website (&quot;IPR&quot;). Any use by User of IPR
              will be only with the prior written permission from Us. You acknowledge that We are
              the sole and exclusive owner of Our respective IPR’s and agree that You will not
              contest the ownership of the said IPR’s for any reason whatsoever.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="heading-text-2 mt-5">11) No Warranties</h3>
            <p className="mt-3">
              The Website and the Services are provided on an &quot;as is&quot; basis. We do not
              make any other representations or warranties of any kind, express or implied,
              including without limitation that the Website or the Services will meet Your
              requirements, will always be available, accessible, uninterrupted, timely, secure, or
              operate without error.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="heading-text-2 mt-5">12) Indemnity</h3>
            <p className="mt-3">
              You shall indemnify and hold harmless Blockskillo Website, its owner, licensee,
              affiliates (as applicable) and their respective officers, directors, agents, and
              employees, from any claim or demand, or actions including reasonable attorneys&apos;
              fees, made by any third party or penalty imposed due to or arising out of Your breach
              of these Terms of Use, Privacy Policy and other terms and conditions, or Your
              violation of any law, rules or regulations or the rights (including infringement of
              intellectual property rights) of a third party.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="heading-text-2 mt-5">13) Limitation of Liability</h3>
            <p className="mt-3">
              In no event shall Blockskillo website be liable for any special, incidental, indirect
              or consequential damages of any kind in connection with these terms of use, even if
              user has been informed in advance of the possibility of such damages.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="heading-text-2 mt-5">14) Updates</h3>
            <p className="mt-3">
              We reserve the right, at Our sole discretion, to change, modify, add or remove
              portions of these Terms of Use, at any time without any prior written notice to You.
              We suggest that You regularly check these Terms of Use to apprise Yourself of any
              updates. Your continued use of the Website following the posting of changes will mean
              that You accept and agree to the revisions. As long as You comply with these Terms of
              Use, We grant You a personal, non-exclusive, non-transferable, limited privilege to
              enter and use the Website.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="heading-text-2 mt-5">15) Severability</h3>
            <p className="mt-3">
              If any of these terms should be determined to be illegal, invalid or otherwise
              unenforceable by reason of the laws of any state in which these terms are intended to
              be effective, then to the extent and within the jurisdiction which that term is
              illegal, invalid or unenforceable, it shall be severed and deleted and the remaining
              Terms of Use shall survive, remain in full force and effect and continue to be binding
              and enforceable.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="heading-text-2 mt-5">16) Non-Assignment</h3>
            <p className="mt-3">
              You shall not assign or transfer or purport to assign or transfer the contract between
              You and Us to any other person.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="heading-text-2 mt-5">
              17) Governing law, jurisdiction and dispute resolution
            </h3>
            <p className="mt-3">
              In the event of a dispute arising out of or in connection with this Terms and
              Conditions, You and Us shall endeavor to mutually resolve the same within a period of
              30 days from the date of notification of dispute. If You and Us hereto are unable to
              settle the dispute amicably, either party may refer the dispute to arbitration in
              accordance with the Arbitration and Conciliation Act, 1996. The arbitration shall be
              presided over by a sole arbitrator jointly appointed by the parties. The venue of
              arbitration shall be Bangalore and the language of arbitration shall be English.{' '}
              <br />
              These Terms of Use are governed by the laws of India. Any action, suit, or other legal
              proceeding, which is commenced to resolve any matter arising under or relating to this
              website, shall be subject to the jurisdiction of the courts at Vadodara, India.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3 className="heading-text-2 mt-5">18) Grievance Offer</h3>
            <p className="mt-3 mb-5">
              In accordance with Information Technology Act 2000, Information Technology
              (Intermediary Guidelines) Rules, 2011 and rules made there under, the name and contact
              details of the Grievance Officer are provided below: <br /> <br />
              Name: Mr. Snehal Bhatt <br />
              Address: 4F-10, Darshaman Arise, Gotri Road, Gotri, Vadodara – 390021. Gujarat. India.
              <br />
              Phone:{' '}
              <a href="tel:+919016070140" className="phone-number">
                +91-9016070140
              </a>{' '}
              <br />
              Email: <a href="mailto:learn@blockskillo.com">learn@blockskillo.com</a>
              <br />
              Time: Monday - Friday 10 AM to 6 PM
            </p>
          </div>
          <div className="col-12 d-flex flex-end">
            <a target='_blank' className='btn btn-group btn_wrapper PrimaryBtn mt-3' onClick={handleDownload}>
              Annual returns 2023-2024
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TermsAndConditions;
