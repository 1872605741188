import React from "react";
import Learn from "../assets/images/learning.png";

function Learning() {
  return (
    <div className="learning">
      <div className="container">
        <img src={Learn} />
      </div>
    </div>
  );
}

export default Learning;
