import React from "react";

import "swiper/css/effect-cube";
import "swiper/css/pagination";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { apiSendEnquiry } from "api/enquiry";
import * as yup from "yup";
import { ToastContainer } from "react-toastify";

export default function HeroSection() {
  const navigate = useNavigate();
  const validationSchema = yup.object().shape({
    enquiryFullName: yup.string().required("Full name is required."),
    enquiryEmail: yup
      .string()
      .required("Email is required.")
      .email("Please enter a valid Email address."),
    enquiryWhatsappNumber: yup.string().required("Contact number is required."),
    highestQualification: yup
      .string()
      .required("Please select highest qualification."),
    currentlyEmployed: yup
      .string()
      .required("Please select currently employed."),
  });

  const initialValues = {
    enquiryFullName: "",
    enquiryEmail: "",
    enquiryWhatsappNumber: "",
    highestQualification: null,
    currentlyEmployed: null,
  };

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    await apiSendEnquiry(values);
    setSubmitting(false);
    resetForm({
      enquiryFullName: "",
      enquiryEmail: "",
      enquiryWhatsappNumber: "",
      highestQualification: null,
      currentlyEmployed: null,
    });
    return navigate("/thank_you_", { state: { enquirySubmitted: true } });
  };

  return (
    <main>
      <section className="section section-hero">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-lg-7 col-md-7  col-12 section-hero-data">
              <div
                className="d-flex justify-content-center flex-column 
                     text-start"
              >
                <h1>
                  Become a Certified <br />
                  <span>Block</span>chain Developer.{" "}
                </h1>
                <h4>Your Gateway to a Future-Ready Career. </h4>
                <div className="section-hero-group">
                  <span>Be job-ready in 1 year</span>
                  <span>More than 30,000 current vacancies on Naukri.com</span>
                  <span>Recession and layoff proof nextgen tech skills</span>
                  <span>Be a part of a rapidly growing Industry</span>
                </div>
                
              </div>
            </div>
            <div className="col-lg-5 col-md-5 col-12 section-hero-slider mt-lg-0 mt-md-0 mt-5">
              <div className="gradient-div">
                <div className="inner-form">
                  <div className="formCard border-gradient border-gradient-purple">
                    <div className="formIcon">
                      <span>
                        Upskill your career with Blockchain certification and
                        get 100% Job Assistance
                      </span>
                    </div>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ values, handleChange }) => (
                        <Form>
                          <div className="mb-0 form-section">
                            <Field
                              value={values.enquiryFullName}
                              onChange={(event) => {
                                handleChange(event);
                              }}
                              type="text"
                              name="enquiryFullName"
                              placeholder="Full Name"
                            />
                            <ErrorMessage
                              name="enquiryFullName"
                              component="div"
                              className="errorShow"
                            />
                          </div>

                          <div className="mb-0 form-section">
                            <Field
                              value={values.enquiryEmail}
                              onChange={(event) => {
                                handleChange(event);
                              }}
                              type="enquiryEmail"
                              name="enquiryEmail"
                              placeholder="Email Id"
                            />
                            <ErrorMessage
                              name="enquiryEmail"
                              component="div"
                              className="errorShow"
                            />
                          </div>

                          <div className="mb-0 form-section">
                            <Field
                              value={values.enquiryWhatsappNumber}
                              onChange={(event) => {
                                handleChange(event);
                              }}
                              type="tel"
                              name="enquiryWhatsappNumber"
                              placeholder="Phone No."
                            />
                            <ErrorMessage
                              name="enquiryWhatsappNumber"
                              component="div"
                              className="errorShow"
                            />
                          </div>
                          <div className="mb-0 form-section">
                            <Field
                              as="select"
                              value={values.highestQualification}
                              onChange={handleChange}
                              name="highestQualification"
                            >
                              <option value={null} selected disabled={true}>
                                Highest Qualification:
                              </option>
                              <option value="12th">12th</option>
                              <option value="underGraduate">
                                Under Graduate
                              </option>
                              <option value="postGraduate">
                                Post Graduate
                              </option>
                            </Field>
                            <ErrorMessage
                              name="highestQualification"
                              component="div"
                              className="errorShow"
                            />
                          </div>
                          <div className="mb-0 form-section">
                            <Field
                              as="select"
                              value={values.currentlyEmployed}
                              onChange={handleChange}
                              name="currentlyEmployed"
                            >
                              <option value={null} selected disabled={true}>
                                Currently Employed : Yes/No
                              </option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </Field>
                            <ErrorMessage
                              name="currentlyEmployed"
                              component="div"
                              className="errorShow"
                            />
                          </div>
                        <button className="dashDownload m-0">Download Brochure</button>
                          <br />
                          {/* <button
                            className="dashDownload "
                            disabled={isSubmitting}
                            type="submit"
                          >
                            Download Brochure
                          </button> */}
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
            {/* <Swiper
                effect={'cube'}
                grabCursor={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                cubeEffect={{
                  shadow: true,
                  slideShadows: true,
                  shadowOffset: 20,
                  shadowScale: 0.94,
                }}
                pagination={true}
                modules={[Autoplay, EffectCube, Pagination]}
                className="mySwiper"
              >
                {certificateRegistry.map((list) => {
                  return (
                    <SwiperSlide key={list.id}>
                      <Card
                        className="primaryCard section-skills-card 
                    d-flex align-items-center justify-content-center
                     flex-column text-center"
                        borderRadius="20px"
                        padding="1rem"
                        height="26rem"
                      >
                        <div className="section-hero-content text-center">
                          <h3>{list.name}</h3>
                          <img src={list.img} alt={list.name} loading="lazy" decoding="async" />
                        </div>
                      </Card>
                    </SwiperSlide>
                  );
                })}
              </Swiper> */}
          </div>
          {/* <div className="enquiry-button-box">
              <Link className='redirect-button' to="/enquiry">Enquire Here</Link>
          </div> */}
        </div>
        <ToastContainer></ToastContainer>
      </section>
    </main>
  );
}
