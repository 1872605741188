import React from "react";
import "styles/video.css";
// import Job1 from "assets/images/job1.png";
// import Job2 from "assets/images/job2.png";
// import Job3 from "assets/images/job3.png";
// import Job4 from "assets/images/job4.png";
// import Job5 from "assets/images/job5.png";
// import Job6 from "assets/images/job6.png";
// import Job7 from "assets/images/job7.png";
// import Job8 from "assets/images/job8.png";
// import Job9 from "assets/images/job9.png";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";

function Job() {
  return (
    <div className="jobSection">
      <div className="container">
        <div className="job">
          <div className="headVideo">
            <Link to="/job"><span>Jobs in Blockchain/Web3</span></Link>
          </div>
          <p>
            Web3-related industries like Fintech, Edtech, Real Estate,
            Hospitality, and more are poised to hire in huge numbers in the
            following application areas. Candidates with a combination of
            technical skills related to Blockchain, along with an understanding
            of the specific industry&apos;s challenges and regulations, will be the
            most effective in driving successful Blockchain-related projects in
            these sectors.
          </p>
          <div className="webButton">
            <a className="btn btn-group">
              Click on sector of your choice below for more.
            </a>
          </div>
          <div className="jobcard">
            <div className="row">
              <div className="col-md-4">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      1. &nbsp; FINANCIAL <br /> SERVICES
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <li>
                          <span>Blockchain Expertise:</span> Seek candidates
                          with a deep understanding of different Blockchain
                          platforms, consensus mechanisms, and smart contract
                          development.
                        </li>
                        <li>
                          <span>DeFi Knowledge:</span> DeFi is a growing sector;
                          candidates with expertise in decentralized finance
                          protocols, yield farming, and liquidity provision are
                          valuable.
                        </li>
                        <li>
                          <span>Regulatory Awareness:</span> Financial services
                          are heavily regulated; look for candidates who can
                          navigate compliance and security considerations.
                        </li>
                        <li>
                          <span>Tokenization Skills:</span> Candidates familiar
                          with token standards (e.g., ERC-20, ERC-721) and their
                          applications in asset tokenization are beneficial.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div className="col-md-4">
                <Accordion>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      2. &nbsp; GAMING & <br />
                      ENTERTAINMENT
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <li>
                          <span>NFT Expertise:</span> Look for candidates who
                          understand NFT standards and their applications in
                          tokenizing in-game assets, digital art, and
                          collectibles.
                        </li>
                        <li>
                          <span>Decentralized Marketplaces:</span> Candidates
                          should be capable of developing platforms for direct
                          transactions between creators and consumers.
                        </li>
                        <li>
                          <span>Blockchain Gaming:</span> Consider candidates
                          with experience in designing games that leverage
                          Blockchain technology for unique gameplay features.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div className="col-md-4">
                <Accordion>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      3. &nbsp; SUPPLY CHAIN <br />
                      MANAGEMENT
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <li>
                          <span>Supply Chain Integration:</span> Candidates
                          should understand how to integrate Blockchain for
                          end-to-end traceability and transparency in supply
                          chains.
                        </li>
                        <li>
                          <span>Smart Contract Development:</span> Knowledge of
                          creating smart contracts to automate supply chain
                          processes, like tracking shipments and verifying
                          authenticity, is essential.
                        </li>
                        <li>
                          <span>Interoperability:</span> Consider candidates who
                          can design systems that connect different supply chain
                          partners on a common Blockchain framework.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div className="col-md-4">
                <Accordion>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>4. &nbsp; HEALTHCARE</Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <li>
                          <span>Health Data Privacy:</span> Candidates should be
                          aware of regulations like HIPAA and GDPR and how
                          Blockchain can provide secure and transparent sharing
                          of health data.
                        </li>
                        <li>
                          <span>Identity Solutions:</span> Knowledge of
                          decentralized identity (DID) systems can help create
                          secure and user-controlled patient identity
                          management.
                        </li>
                        <li>
                          <span>Healthcare Record Management:</span> Look for
                          candidates with expertise in building systems that
                          securely store and share electronic health records
                          using Blockchain.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div className="col-md-4">
                <Accordion>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>5. &nbsp; REAL ESTATE</Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <li>
                          <span>Property Tokenization:</span> Candidates should
                          understand how to tokenize real estate assets and
                          develop platforms for fractional ownership and
                          trading.
                        </li>
                        <li>
                          <span>Smart Contracts for Transactions:</span> Seek
                          candidates with experience in creating smart contracts
                          that automate real estate transactions, reducing
                          paperwork and intermediaries.
                        </li>
                        <li>
                          <span>Regulatory Understanding:</span> Real estate is
                          regulated; candidates who can align Blockchain
                          solutions with legal requirements are valuable.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div className="col-md-4">
                <Accordion>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>6. &nbsp;AGRICULTURE</Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <li>
                          <span>Food Traceability Solutions:</span> Look for
                          candidates who can develop platforms for tracking the
                          journey of agricultural products from farm to table
                          using Blockchain.
                        </li>
                        <li>
                          <span>Supply Chain Efficiency:</span> Candidates
                          should be capable of designing systems that streamline
                          agricultural supply chains by reducing intermediaries.
                        </li>
                        <li>
                          <span>Interoperability:</span> Consider candidates who
                          can create Blockchain systems that connect different
                          stakeholders in the agricultural sector.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div className="col-md-4">
                <Accordion>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      7. &nbsp; VOTING & <br /> GOVERNANCE
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <li>
                          <span>Food Traceability Solutions:</span> Look for
                          candidates who can develop platforms for tracking the
                          journey of agricultural products from farm to table
                          using Blockchain.
                        </li>
                        <li>
                          <span>Supply Chain Efficiency:</span> Candidates
                          should be capable of designing systems that streamline
                          agricultural supply chains by reducing intermediaries.
                        </li>
                        <li>
                          <span>Interoperability:</span> Consider candidates who
                          can create Blockchain systems that connect different
                          stakeholders in the agricultural sector.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div className="col-md-4">
                <Accordion>
                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      8. &nbsp; RETAIL & <br /> E-COMMERCE
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <li>
                          <span>Supply Chain Transparency:</span> Candidates
                          familiar with implementing Blockchain for transparent
                          supply chains and verifying product authenticity are
                          ideal.
                        </li>
                        <li>
                          <span>Tokenized Loyalty Programs:</span> Seek
                          candidates who can design tokenized loyalty rewards
                          programs to enhance customer engagement and retention.
                        </li>
                        <li>
                          <span>NFT Applications:</span> Candidates
                          knowledgeable about NFTs can help retail and
                          e-commerce businesses explore new ways to engage
                          customers.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div className="col-md-4">
                <Accordion>
                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      9. &nbsp; Energy and <br /> Utilities
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <li>
                          <span>Energy Trading Knowledge:</span> Candidates
                          should grasp the concept of peer-to-peer energy
                          trading using Blockchain and its impact on the energy
                          sector.
                        </li>
                        <li>
                          <span>Decentralized Grid Management:</span> Look for
                          candidates who can design solutions to enhance the
                          efficiency and reliability of energy distribution
                          grids.
                        </li>
                        <li>
                          <span>Interoperable Systems:</span> Consider
                          candidates who can develop systems that allow
                          different energy sources and providers to interact
                          seamlessly.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Job;
