import instance, { errorMessage } from "api";

export const apiLoginWithEmail = async (payload) => {
    try {
        const response = await instance.post('/auth/login-email-otp', payload);
        return { status: true, data : response.data };
    } catch (error) { return { status: false, error: errorMessage(error) }; }   
};

export const apiSignUpWithEmail = async (payload) => {
    try {
        const response = await instance.post('/auth/signup', payload);
        return { status: true, data : response.data };
    } catch (error) { return { status: false, error: errorMessage(error) }; }  
};
  
export const apiLoginWithEmailVerify = async (payload) => {
    try {
        const response = await instance.post('/auth/login-email-verify', payload);
        return { status: true, data : response.data };
    } catch (error) { return { status: false, error: errorMessage(error) }; }  
};
